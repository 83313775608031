import * as Yup from 'yup';



// const validFileExtensions = { image: ['jpg', 'gif', 'png', 'jpeg', 'svg', 'webp'] };

// function isValidFileType(fileName, fileType) {
//     return fileName && validFileExtensions[fileType].indexOf(fileName.split('.').pop()) > -1;
// }

export const Loginvalidationschema = Yup.object({

    email: Yup.string()
        .min(3, 'must be at least 3 characters long')
        .email('must be a valid email')
        .required('Email is Required'),
    password: Yup
        .string()
        .required('Password is required')
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{10,})/, "Password Is Invalid"),
});
export const Forgotvalidationschema = Yup.object({
    email: Yup.string()
        .min(3, 'must be at least 3 characters long')
        .email('must be a valid email')
        .required('Email is Required'),
});



export const Signupvalidationschema = Yup.object({
    first_name: Yup.string()
        .min(2, 'Too Short!')
        .max(15, 'Too Long!')
        .required('First Name is Required'),
    last_name: Yup.string()
        .min(3, 'Too Short!')
        .max(15, 'Too Long!')
        .required('Last Name is Required'),
    address1: Yup.string().required('Address is Required'),
    city: Yup.string().required('City is Required'),
    state: Yup.string().required('State is Required'),
    zip: Yup.string().required('Zip Code is Required'),
    // checkbox: Yup.boolean().oneOf([true], 'Must Check The Checkbox'),
    checkbox: Yup.boolean().oneOf([true], 'Please agree to the terms and conditions'),


    // checkbox: Yup.boolean().oneOf([true], 'Must Sign The Agreement'),

    email: Yup.string()
        .min(3, 'must be at least 3 characters long')
        .email('must be a valid email')
        .required('Email is Required'),
    password: Yup
        .string()
        .required('Password is required')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{10,})/,
            "Must Contain 10 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        ),
    confirmpassword: Yup.string().required('Confirm Password is required').oneOf([Yup.ref('password'), null], 'Confirm Password must match with  Password'),
    // license: Yup.mixed()
    //     .nullable()
    //     .notRequired()
    //     .required('Please Upload Image'),
    // insurance: Yup.mixed()
    //     .nullable()
    //     .notRequired()
    //     .required('Please Upload Image'),



    // license: Yup.mixed().when('user_type', {
    //     is: 'guest',
    //     then: Yup.mixed().nullable().required('Please Upload Image'),
    //     otherwise: Yup.mixed().nullable().notRequired()
    // }),



    license: Yup.mixed().when("user_type", {
        is: 'guest',
        then: () => Yup.mixed().nullable().required('Please Upload Image'),
        otherwise: () => Yup.mixed().nullable().notRequired()
    }),



    insurance: Yup.mixed().when("user_type", {
        is: 'guest',
        then: () => Yup.mixed().nullable().required('Please Upload Image'),
        otherwise: () => Yup.mixed().nullable().notRequired()
    }),





    // .nullable()
    // .notRequired()
    // .required('Please Upload Image'),
    // insurance: Yup.mixed()
    //     .nullable()
    //     .notRequired()
    //     .required('Please Upload Image'),


    // license: Yup.mixed().when('user_type', {
    //     is: 'guest',
    //     then: Yup.mixed().nullable().required('Please Upload Image'),
    //     otherwise: Yup.mixed().nullable().notRequired(),
    // }),
    // insurance: Yup.mixed().when('user_type', {
    //     is: 'guest',
    //     then: Yup.mixed().nullable().required('Please Upload Image'),
    //     otherwise: Yup.mixed().nullable().notRequired(),
    // }),

    phone: Yup.string()
        .matches(/^[0-9]+$/, 'Phone Number must contain only numbers')
        .min(10, 'Phone Number must contain at least 10 numbers')
        .max(16, 'Phone Number must contain at most 16 numbers')
        .required('Phone Number is required'),
});


// export const Changepasswordschema = Yup.object({
//     current_password: Yup
//         .string()
//         .required('Password is required')
//         .matches(
//             /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{10,})/,
//             "Password Is Invalid"
//         ),
//     password: Yup
//         .string()
//         .required('Password is required')
//         .matches(
//             /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{10,})/,
//             "Must Contain 10 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
//         ),
//     password_confirmation: Yup.string().required('Confirm Password is required').oneOf([Yup.ref('password'), null], 'Confirm Password must match with  Password'),
// });


export const Changepasswordschema = Yup.object({
    current_password: Yup.string()
        .required('Password is required')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{10,})/,
            'Password Is Invalid'
        ),
    password: Yup.string()
        .required('Password is required')
        .notOneOf([Yup.ref('current_password'), null], 'New password must be different from the current password')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{10,})/,
            'Must Contain 10 Characters, One Uppercase, One Lowercase, One Number, and One Special Case Character'
        ),
    password_confirmation: Yup.string()
        .required('Confirm Password is required')
        .oneOf([Yup.ref('password'), null], 'Confirm Password must match with Password'),
});


export const Emailmodal = Yup.object({

    email: Yup.string()
        .min(3, 'must be at least 3 characters long')
        .email('must be a valid email')
        .required('Email is Required'),
});

export const Profileschema = Yup.object({

    first_name: Yup.string()
        .min(2, 'Too Short!')
        .max(15, 'Too Long!')
        .required('First Name is Required'),
    last_name: Yup.string()
        .min(3, 'Too Short!')
        .max(15, 'Too Long!')
        .required('Last Name is Required'),
    email: Yup.string()
        .min(3, 'must be at least 3 characters long')
        .email('must be a valid email')
        .required('Email is Required'),
    phone: Yup.string()
        .matches(/^[0-9]+$/, 'Phone Number must contain only numbers')
        .min(10, 'Phone Number must contain at least 10 numbers')
        .max(16, 'Phone Number must contain at most 16 numbers')
        .required('Phone Number is required'),

    address1: Yup.string().required('Address is Required'),
    city: Yup.string().required('City is Required'),
});

export const Vendoraddressschema = Yup.object({
    propertyaddress: Yup.string().required('Property Address is Required'),
    city: Yup.string().required('City is Required'),
    state: Yup.string().required('State is Required'),
    zipcode: Yup.string().required('Zip Code is Required'),
    // unit: Yup.string().required('Unit is Required'),
    // gatecode: Yup.string().required('Gate Code is Required'),
    // housecode: Yup.string().required('House Code is Required'),
    // garagecode: Yup.string().required('Garage Code is Required'),
});

// export const Vendorcartschema = Yup.object().shape({
//     // cart: Yup.boolean().required('Cart Preference is Required'),
//     // cart: Yup.boolean().required('Cart Preference is Required'),

//     // cart: Yup.mixed().required('Cart Preference is Required'),
//     // cart: Yup.number().required('Cart Preference is Required'),

//     // cart: Yup.array().of(
//     //     Yup.object().shape({ required('Cart Preference is Required') })),


//     // cart: Yup.array().of(Yup.object().shape({ required: Yup.string().required('Cart Preference is Required') })
//     // ).required('Cart Preference is Required'),

//     cart: Yup.array().of(
//         Yup.object().shape({
//             required: Yup.string().required('Cart Preference is Required')
//         })
//     ).required('At least one Cart Preference is Required'),

//     color: Yup.string().required('Color is Required'),
//     make: Yup.string().required('Make is Required'),
//     model: Yup.string().required('Model is Required'),
//     year: Yup.string().required('Year is Required'),
//     vin: Yup.string().required('Vin is Required'),
//     licenseplatenumber: Yup.string().required('License Plate Number is Required'),
//     drive: Yup.string().required('Drive is Required'),
//     serialnumber: Yup.string().required('Serial Number is Required'),
//     // image: Yup.mixed().when('submit', {
//     //     is: true,
//     //     then: Yup.mixed()
//     //         .nullable()
//     //         .required('Please Upload Image'),
//     // }),
//     // image: Yup.mixed()
//     //     .nullable()
//     //     .notRequired()
//     //     .required('Please Upload Image'),

//     // image: Yup.mixed().nullable().notRequired().required('Please Upload Image'),
//     image: Yup.mixed().nullable().required('Please Upload Image'),
//     // image: Yup.mixed().when('submit', {
//     //     is: true,
//     //     then: Yup.mixed()
//     //         .nullable()
//     //         .required('Please Upload Image'),
//     // }),
//     // submit: Yup.boolean(),

// });

export const Vendorcartschema = Yup.object().shape({
    // cart: Yup.array().of(
    //     Yup.object().shape({
    //         id: Yup.string().required('Cart Preference ID is Required')
    //     })
    // ).required('At least one Cart Preference is Required'),
    // cart: Yup.string().required('Cart Preference is Required'),

    cart: Yup.mixed().transform((value) => (value ? value.id : null)).required('Cart is Required'),

    // Other fields with their respective validations
    color: Yup.string().required('Color is Required'),
    make: Yup.string().required('Make is Required'),
    model: Yup.string().required('Model is Required'),
    year: Yup.string().required('Year is Required'),
    vin: Yup.string().required('Vin is Required'),
    licenseplatenumber: Yup.string().required('License Plate Number is Required'),
    drive: Yup.string().required('Drive is Required'),
    serialnumber: Yup.string().required('Serial Number is Required'),
    image: Yup.mixed().nullable().required('Please Upload Image'),
});



export const Formschema = Yup.object({

    address: Yup.string().required('Address is Required'),
    cart: Yup.mixed().transform((value) => (value ? value.id : null)).required('Cart is Required'),

});



export const Bankcardschema = Yup.object().shape({

    // number: Yup.string()
    //     .required('Card Number is required')
    //     .matches(/^\d{16}$/, 'Enter Proper 16-digit Card number')
    //     .matches(/^\d+$/, 'Card number must contain only digits')
    //     .test('maxLen', 'Maximum length exceeded', value => value && value.length <= 16),


    number: Yup.string()
        .required('Card Number is required')
        // .matches(/^\d{16}$/, 'Enter Proper 16-digit Card number')
        .matches(/^\d+$/, 'Card number must contain only digits')
        .test('maxLen', 'Maximum length exceeded', value => value && value.length <= 19)
        .min(14, 'Card number must be at least 14 digits long')
        .max(19, 'Card number must be at most 19 digits long'),

    name: Yup.string()
        .required("Cardholder's name is required")
        .matches(/^[a-zA-Z\s]*$/, 'Invalid name'),

    expiry: Yup.string()
        .required('Expiration Date is required')
    // .test('isValidDate', 'Invalid expiration date', function (value) {
    //     if (!value || value.length !== 5) {
    //         return false;
    //     }

    // const month = parseInt(value.substring(0, 2), 10);
    // const year = parseInt(value.substring(2), 10);

    // // Additional conditions based on split values
    // const isValidMonth = month >= 1 && month <= 12;
    // const currentYear = new Date().getFullYear();
    // const isValidYear = year + 2000 >= currentYear;

    // return isValidMonth && isValidYear;
    // }
    // )
    ,

    cvc: Yup.string().required('CVV is required')
        // .matches(/^\d{4}$/, 'CVV must be 3 digits')
        .min(3, 'Cvc must be at least 3 digits')
        .max(4, 'Card number must be at most 19 digits long'),


    // number: Yup.string()
    //     .matches(/^\d{4} \d{4} \d{4} \d{4}$/, 'Invalid Credit Card number')
    //     .required('Card number is required'),
    // cvc: Yup.string()
    //     .matches(/^\d{3}$/, 'Invalid CVV')
    //     .required('CVV is required'),
    // expiry: Yup.string()
    //     .matches(/^(0[1-9]|1[0-2])\/\d{2}$/, 'Invalid expiry date')
    //     .required('Expiry date is required'),
    // name: Yup.string()
    //     .required("Cardholder's name is required")
    //     .matches(/^[a-zA-Z\s]*$/, 'Invalid name'),

});





