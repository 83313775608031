import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { get } from "../../utils/axiosInstance";
import { Images } from "../../utils/constants";
import Spinner from '../Spinner';
import Step1 from '../../assets/Png/Step1.png'
import Step2 from '../../assets/Png/Step2.png'

export function Steps({ showStepsmodal, setShowstepsmodal }) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showResponse, setShowResponse] = useState([]);
    const [loader, setLoader] = useState(false);
    const Token = useSelector((state) => state.token);

    // useEffect(() => {
    //     setLoader(true);
    //     get(`/reservation-guide`, {}, {}, Token)
    //         .then((res) => {
    //             setLoader(false);
    //             setShowResponse(res.response?.data?.reservation_guide || []);
    //         })
    //         .catch((err) => {
    //             setLoader(false);
    //             console.log("Steps Error:", err);
    //         });
    // }, [Token]);


    const stepImages = [
        Images.Step1,
        Images.Step2,
        Images.Step3,
        Images.Step4,
        Images.Step5,
        Images.Step6,
        Images.Step7,
        Images.Step8,
        Images.Step9,
        Images.Step10,
        Images.Step11,
    ];


    const currentImage = showResponse[currentIndex]?.media || stepImages[currentIndex];

    const changeStep = (increment) => {
        const newIndex = currentIndex + increment;
        if (newIndex >= 0 && newIndex < stepImages.length) {
            setCurrentIndex(newIndex);
        }
    };

    const closeStepsModal = () => {
        setShowstepsmodal(false);
    };



    const handleButtonClick = (direction) => {
        if (direction === "prev") {
            setCurrentIndex((prevIndex) =>
                prevIndex > 0 ? prevIndex - 1 : stepImages.length - 1
            );
        } else if (direction === "next") {
            setCurrentIndex((prevIndex) =>
                prevIndex < stepImages.length - 1 ? prevIndex + 1 : 0
            );
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "ArrowLeft") {
            handleButtonClick("prev");
        } else if (e.key === "ArrowRight") {
            handleButtonClick("next");
        }
    };

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [])

    if (loader) {
        return (
            <div className="flex justify-center items-center w-full lg:h-[670px] md:h-[670px] h-[650px]">
                <Spinner className="spinner" isLoading={loader} />
            </div>
        );
    } else {
        return (
            <div>
                {showStepsmodal ?
                    <>
                        <div className='fixed inset-0 z-50 h-full w-full flex items-center justify-center'>

                            <div className="lg:w-[70%] w-[100%] flex items-center lg:gap-8 gap-3 justify-center">
                                <div>
                                    <button
                                        onClick={() => changeStep(-1)}
                                        className={`${currentIndex === 0 ? 'bg-gray-100 text-Textdarkcolor' : 'text-Textdarkcolor bg-white'}  
                                rounded-full z-50 lg:text-[25px] text-[22px] lg:w-12 lg:h-12 w-9 h-9 cursor-pointer`}
                                        disabled={currentIndex === 0}
                                    >
                                        &larr;
                                    </button>
                                </div>
                                <div className="lg:w-[70%] md:w-[40%] w-[70%]">
                                    <div className="border-0 rounded-lg shadow-lg mx-auto relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                        <div className="flex bg-Appcolor p-5 border-b border-solid border-blueGray-200 rounded-t">
                                            <h3 className="lg:text-[18px] text-[15px] w-full flex justify-center mx-auto text-white font-medium">
                                                Reservation Guide
                                            </h3>
                                            <button className="w-0 h-2 rounded-2xl mt-[-35px] mr-[-2px]" onClick={() => closeStepsModal(false)}>
                                                <span className="bg-white w-8 h-8 rounded-3xl text-black text-2xl block outline-none focus:outline-none">
                                                    ×
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    <img
                                        className="w-[100%] lg:h-[600px] h-[400px] "
                                        // src={stepImages[currentImage]}

                                        src={currentImage}
                                        alt={`Step ${currentIndex + 1}`}
                                    />
                                </div>

                                <div className=''>
                                    <button
                                        onClick={() => changeStep(+1)}
                                        className={`${currentIndex === showResponse.length - 1 ? 'bg-gray-100 text-Textdarkcolor' : 'text-Textdarkcolor bg-white'} rounded-full z-50 text-Textdarkcolor lg:text-[25px] text-[22px] lg:w-12 lg:h-12 w-9 h-9 cursor-pointer flex-end`}
                                        disabled={currentIndex === showResponse.length - 1} >
                                        &rarr;
                                    </button>
                                </div>

                                {/* <div>

                                    <button onClick={() => handleButtonClick("prev")}>Previous</button>
                                    <button onClick={() => handleButtonClick("next")}>Next</button>
                                </div> */}









                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                    : null}
            </div>
        );
    }
};