import React, { useState, useEffect } from "react";
// import { get } from "../../utils/axiosInstance";
import moment from "moment";
import Spinner from "../Spinner";
import NOBOOKING from "../../assets/Png/Nobooking.png";

const Reservationsidecard = ({
  setActiveComponent,
  handleReservationClick,
  reservations,
  loader,
}) => {
//   console.log(
//     "reservation ka sidecard",
//     reservations.map((item) => item.created_at)
//   );

  if (loader) {
    return (
      <div className="flex justify-center items-center w-full lg:h-[670px] md:h-[670px] h-[650px]">
        <Spinner className="spinner" isLoading={loader} />
      </div>
    );
  } else {
    return (
      <div className="h-[100%]">
        <div className=" bg-[#FFFFFF] rounded-[10px] flex flex-col lg:w-[80%] w-full h-full ">
          <div className="p-4 border-b-[1px] border-[#EDF0F6]">
            <p className="text-[25px] font-semibold">Reservations</p>
          </div>
          <div className="custom-scrollbar lg:w-full md:h-[600px] h-full">
            {/* {loading && <p>Loading...</p>} */}
            {reservations.length > 0 ? (
              reservations.map((item) => (
                <div
                  key={item.id}
                  className="lg:w-[90%] cursor-pointer w-[95%] mx-auto mt-2 lg:rounded-[20px_0px_0px_20px] rounded-[20px] border-[2px] border-[#EDF0F6]  h-[135px]   line-clamp-1 overflow-hidden"
                  onClick={() => handleReservationClick(item.id, reservations)}
                >
                  <div className="flex flex-row w-full">
                    <div className="w-[20%]">
                      <p className="bg-Appcolor h-[135px] flex items-center justify-center lg:rounded-[20px_0px_0px_20px] text-center rounded-[20px] w-full   lg:text-[20px] text-[15px] text-white font-semibold">
                        {moment(item.created_at).format("DD MMM")}
                      </p>
                    </div>

                    <div className="flex flex-col justify-center items-center text-center w-[80%]   h-[120px]">
                      <div className="border-b-[1px] border-[#EDF0F6] w-[90%] overflow-hidden">
                        <p className="text-[16px] py-3 whitespace-nowrap overflow-hidden overflow-ellipsis">
                          {item.address_details}
                        </p>
                      </div>
                      <p className="text-Appcolor py-3">
                        {moment(item.start_date).format("DD MMM YYYY")} -{" "}
                        {moment(item.end_date).format("DD MMM YYYY")}
                      </p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="   flex flex-col justify-center items-center h-[100%]">
                <img
                  className="w-[100px] h-[100px]   mx-auto"
                  src={NOBOOKING}
                />
                <p className="flex font-medium justify-center items-center mt-4 ml-5">
                  No bookings found.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default Reservationsidecard;
