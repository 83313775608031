import React, { useState, useEffect } from "react";
import Inputfield from "../../components/Inputfiled";
import Dropdown from "../../components/Dropdown";
import Successmodal from "../Modals/Successmodal";
import Upload from "../../assets/Png/Upload.png";
import { Vendorcartschema } from "../../utils/schema";
import { useFormik } from "formik";
import { get, post } from "../../utils/axiosInstance";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { setLogin, setUserState } from "../../state";
import Toast from "../Toast";

const Vendorcartinfo = ({ switchComponent, goToPreviousStep }) => {
  const dispatch = useDispatch();
  const [fileName, setFileName] = useState(null);
  const [thumbnail, setThumbnail] = useState(null); // Add this line
  const [successModal, setShowsuccessModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const user = useSelector((state) => state.user);
  const formik = useFormik({
    initialValues: {
      color: "",
      make: "",
      model: "",
      cart: "",
      year: "",
      vin: "",
      licenseplatenumber: "",
      drive: "",
      serialnumber: "",
      image: null,
    },
    validationSchema: Vendorcartschema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  // const showToastMessage = () => {
  //   toast.error("First Enter The Correct Information", {
  //     position: toast.POSITION.BOTTOM_RIGHT,
  //   });
  // };

  const handleBackClick = () => {
    switchComponent("Vendoraddressinfo")
    goToPreviousStep()
  }

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    const Token = localStorage.getItem("token");
    get("/category", {}, {}, Token)
      .then((res) => {
        console.log("res", res);
        const categoryData = res.response.data.categories;
        setCategories(categoryData);
        const storedCategoryId = localStorage.getItem("selectedCategoryId");

      })
      .catch((err) => {
        toast.error(err.response.data.error.messages[0], {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  // const handleFileChange = (e) => {
  //   const selectedFile = e.currentTarget.files[0];

  //   if (selectedFile) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       formik.setFieldValue("image", selectedFile);
  //       setFileName(selectedFile.name);
  //       setThumbnail(reader.result);
  //       formik.validateForm();
  //     };
  //     reader.readAsDataURL(selectedFile);
  //   } else {
  //     formik.setFieldValue("image", null);
  //     setFileName(null);
  //     setThumbnail(null);
  //     if (!e.currentTarget.contains(document.activeElement)) {
  //       formik.setFieldError("image", "");
  //     }
  //   }
  // };

  const handleFileChange = (e) => {
    const selectedFile = e.currentTarget.files[0];

    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        formik.setFieldValue("image", selectedFile);
        setFileName(selectedFile.name);
        setThumbnail(reader.result);
        formik.validateForm();

        formik.setFieldTouched("image", false);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      formik.setFieldValue("image", null);
      setFileName(null);
      setThumbnail(null);

      formik.setFieldTouched("image", true);

      if (!e.currentTarget.contains(document.activeElement)) {
        formik.setFieldError("image", "");
      }
    }
  };


  const app_vendor_id = useSelector((state) => state.app_vendor_id);

  const handleSubmit = (e) => {
    const catId = localStorage.getItem("selectedCategoryId");
    const Token = localStorage.getItem("token");
    const address_id = localStorage.getItem("addressId");

    const formdata = new FormData();
    formdata.append("address_id", address_id);
    formdata.append("vendor_id", app_vendor_id);
    formdata.append("image", e.image);
    formdata.append("color", e.color);
    formdata.append("make", e.make);
    formdata.append("model", e.model);
    formdata.append("year", e.year);
    formdata.append("vin", e.vin);
    formdata.append("license_plate_number", e.licenseplatenumber);
    formdata.append("drive", e.drive);
    formdata.append("category_id", catId);
    formdata.append("serial_number", e.serialnumber);

    // console.log("formdata inside", [...formdata]);

    post("/cart/create", formdata, {}, Token)
      .then((res) => {
        if (res) {
          const Data = res.response.data.cart;
          console.log('Data 123', Data)
          dispatch(setUserState({
            ...user,
            cart: Data
          }));
          setShowsuccessModal(true);
        } else {
          Toast({ message: 'First Enter The Correct Information.', isError: true });
        }
      })
      .catch((err) => {
        Toast({ message: 'First Enter The Correct Information.', isError: true });
      });
  };










  return (
    <div className="bg-[#FFFFFF] rounded-[10px] flex flex-col items-center lg:w-[100%]  md:h-[670px]  h-[87vh]">
      <form className="w-[95%] custom-scrollbar h-[630px] mt-[15px]" onSubmit={formik.handleSubmit} >

        <div className="  mx-auto lg:gap-x-28 grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1">

          <div className="mt-3">
            <Dropdown
              labelstyle="flex text-[18px] text-Textdarkcolor font-medium"
              divstyle="mt-1 w-full border-b-[1px] focus:outline-none border-[#f5f5f5] "
              label="Cart Preference*"
              id="cart"
              name="cart"
              placeholder="Choose your preferred cart passengers"
              options={categories.map((category) => ({
                id: category.id,
                value: category.name,
                label: category.description,
                item: category,
              }))}
              value={formik.values.cart?.name ?? ""}
              onChange={(e) => {
                localStorage.setItem(
                  "selectedCategoryId",
                  String(e.target.value.id)
                );
                setSelectedCategoryId(e.target.value.id);
                formik.setFieldValue("cart", e.target.value);
              }}
              error={formik.errors.cart}
              touched={formik.touched.cart}
            />
          </div>

          <Inputfield
            labelstyle="flex text-[18px] pt-[20px] text-Inputtextcolor font-medium"
            inputStyle="mt-1 py-2 w-full border-b-[1px] focus:outline-none border-[#f5f5f5]"
            Labelname="Color*"
            type="text"
            placeholder="Enter your Color"
            name="color"
            htmlFor="color"
            id="color"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.color}
            errors={formik.errors.color}
            touched={formik.touched.color}
          />

          <Inputfield
            labelstyle="flex text-[18px] pt-[20px] text-Inputtextcolor font-medium"
            inputStyle="mt-1 py-2 w-full border-b-[1px] focus:outline-none border-[#f5f5f5]"
            Labelname="Make*"
            type="numeric"
            placeholder="Enter your Make"
            name="make"
            htmlFor="make"
            id="make"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.make}
            errors={formik.errors.make}
            touched={formik.touched.make}
          />

          <Inputfield
            labelstyle="flex text-[18px] pt-[20px] text-Inputtextcolor font-medium"
            inputStyle="mt-1 py-2 w-full border-b-[1px] focus:outline-none border-[#f5f5f5]"
            Labelname="Model*"
            type="numeric"
            placeholder="Enter your Model"
            name="model"
            htmlFor="model"
            id="model"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.model}
            errors={formik.errors.model}
            touched={formik.touched.model}
          />

          <Inputfield
            labelstyle="flex text-[18px] pt-[20px] text-Inputtextcolor font-medium"
            inputStyle="mt-1 py-2 w-full border-b-[1px] focus:outline-none border-[#f5f5f5]"
            Labelname="Year*"
            type="numeric"
            placeholder="Enter your Year"
            name="year"
            htmlFor="year"
            id="year"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.year}
            errors={formik.errors.year}
            touched={formik.touched.year}
          />

          <Inputfield
            labelstyle="flex text-[18px] pt-[20px] text-Inputtextcolor font-medium"
            inputStyle="mt-1 py-2 w-full border-b-[1px] focus:outline-none border-[#f5f5f5]"
            Labelname="VIN*"
            type="numeric"
            placeholder="Enter your Vin"
            name="vin"
            htmlFor="vin"
            id="vin"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.vin}
            errors={formik.errors.vin}
            touched={formik.touched.vin}
          />

          <Inputfield
            labelstyle="flex text-[18px] pt-[20px] text-Inputtextcolor font-medium"
            inputStyle="mt-1 py-2 w-full border-b-[1px] focus:outline-none border-[#f5f5f5]"
            Labelname="Serial Number*"
            type="numeric"
            placeholder="Enter your Serial Number"
            name="serialnumber"
            htmlFor="serialnumber"
            id="serialnumber"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.serialnumber}
            errors={formik.errors.serialnumber}
            touched={formik.touched.serialnumber}
          />

          <Inputfield
            labelstyle="flex text-[18px] pt-[20px] text-Inputtextcolor font-medium"
            inputStyle="mt-1 py-2 w-full border-b-[1px] focus:outline-none border-[#f5f5f5]"
            Labelname="License Plate Number*"
            type="numeric"
            placeholder="Enter your License Plate Number"
            name="licenseplatenumber"
            htmlFor="licenseplatenumber"
            id="licenseplatenumber"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.licenseplatenumber}
            errors={formik.errors.licenseplatenumber}
            touched={formik.touched.licenseplatenumber}
          />

          <Dropdown
            labelstyle="flex text-[18px] pt-[20px] text-Inputtextcolor font-medium"
            divstyle="mt-1 py-2 w-full border-b-[1px] focus:outline-none border-[#f5f5f5]"
            label="Drive*"
            id="drive"
            name="drive"
            placeholder="Select Drive Type"
            options={[
              { value: "electric", label: "Electric" },
              { value: "gas", label: "Gas" },
            ]}
            value={formik.values.drive}
            onChange={formik.handleChange}
            error={formik.errors.drive}
            touched={formik.touched.drive}
          />

          <div className="flex flex-col">
            <label className="relative inline-flex items-center mt-2 justify-center w-40 h-32 border-[1px] rounded-[10px] border-gray-300 cursor-pointer bg-[#F8F8F8]">
              <input
                type="file"
                id="image"
                accept="image/png, image/jpeg, image/jpg"
                className="absolute object-contain inset-0 w-full h-full opacity-0 cursor-pointer"
                onChange={handleFileChange}
                onBlur={formik.handleBlur}
                onFocus={() => formik.setFieldTouched("image", true)}
              />
              {thumbnail ? (
                <img
                  src={thumbnail}
                  alt="Thumbnail"
                  className="w-full h-full object-cover rounded-[10px]"
                />
              ) : (
                <img src={Upload} alt="Upload Icon" />
              )}
            </label>
            {formik.errors.image && formik.touched.image && (
              <div className="text-start text-red-500 ">
                {formik.errors.image}
              </div>
            )}
          </div>



        </div>
        <div className="flex justify-between mx-auto lg:mt-9 my-4">
          <button
            className={`text-[16px] rounded-[10px] lg:w-[145px] lg:h-[50px] md:w-[130px] md:h-[40px] w-[110px] h-[35px]  bg-gray-200 text-gray-500`}
            onClick={handleBackClick}
          >
            Back
          </button>
          <button
            className="bg-Appcolor text-white text-[16px] rounded-[10px] lg:w-[145px] lg:h-[50px] md:w-[130px] md:h-[40px] w-[110px] h-[35px]"
            type="submit"
          >
            Finish
          </button>
        </div>
      </form >
      <Successmodal
        successModal={successModal}
        setShowsuccessModal={setShowsuccessModal}
      />
      <ToastContainer limit={1} />
    </div >
  );
};

export default Vendorcartinfo;
