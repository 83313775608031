import React, { useEffect, useState } from "react";
// import Video from "../../assets/Png/Video.mp4";
import { get } from "../../utils/axiosInstance";
import { useSelector } from "react-redux";
import Spinner from "../Spinner";

export function Howitwork({ showHowitworkmodal, setShowhowitworkmodal }) {


  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);
  const Token = useSelector((state) => state.token);

  useEffect(() => {
    setLoader(true);
  
    get(`/content/content/how-it-works`, {}, {}, Token)
      .then((res) => {
        setLoader(false);
        const response = res.response.data.content.media;
  
        console.log("media", response);
        setData(response);
      })
      .catch((err) => {
        setLoader(false);
        console.error("Error fetching data:", err);
      })
      .finally(() => {
        setLoader(false);
      });
  }, []);
  

 
  if (loader) {
    return (
        <div className="flex justify-center items-center w-full lg:h-[670px] md:h-[670px] h-[650px]">
            <Spinner className="spinner" isLoading={loader} />
            

        </div>
    );
} else {
  return (
    <>
      {showHowitworkmodal ? (
        <>
          {/* <div className='fixed inset-0 z-50 h-full w-full flex items-center justify-center'>
             
            <div className="lg:w-1/2 w-full p-5 px-20 mx-auto relative z-50">
              <div className="bg-white border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none"> */}
          <div className="justify-center items-center flex fixed inset-0 z-50   ">
            <div className="lg:w-[50%]  w-[100%] fixed p-5  md:px-20 mx-auto">
              <div className="border-0 rounded-lg shadow-lg relative      flex flex-col w-full bg-white outline-none focus:outline-none ">
                <div className="flex bg-Appcolor p-5 border-b border-solid border-blueGray-200 rounded-t-lg">
                  <h3 className="lg:text-18 text-14 w-full flex justify-center mx-auto text-white font-medium">
                    How It works
                  </h3>
                  <button
                    className="w-0 h-2 rounded-2xl mt-[-35px] mr-[-2px] focus:outline-none"
                    onClick={() => setShowhowitworkmodal(false)}
                  >
                    <span className="bg-white w-8 h-8 rounded-3xl text-black text-2xl block">
                      ×
                    </span>
                  </button>
                </div>
                <div className="w-full  h-full">
                  <video controls autoPlay className="w-full  h-full ">
                    <source src={data} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
}