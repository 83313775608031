import moment from "moment";
import { toast } from "react-toastify";
import Toast from "./Toast";

export const handleDateClick = (ranges, hasBookingDates, setValues, onDateClick, setIsNextButtonDisabled) => {
    if (ranges.length > 1) {
        const start = ranges[0].format('MM/DD/YYYY');
        const end = ranges[1].format('MM/DD/YYYY');
        const startDate = moment(start, 'MM/DD/YYYY');
        const endDate = moment(end, 'MM/DD/YYYY');
        const daysDifference = endDate.diff(startDate, 'days');

        localStorage.setItem("daysDifference", daysDifference);
        localStorage.setItem("startDate", start);
        localStorage.setItem("endDate", end);

        const hasBookedDateInRange = hasBookingDates.some((bookedDate) => {
            const date = moment(bookedDate, "MM/DD/YYYY", true);
            return date.isValid() && date.isBetween(startDate, endDate, null, '[]');
        });

        if (hasBookedDateInRange) {
            setIsNextButtonDisabled(true)
            Toast({ message: "Cannot select this range. Booked dates found in between.", isError: true });
        } else {
            setValues(ranges);
            if (onDateClick) {
                onDateClick(ranges);
            }
            const dateArray = [];
            const currentDate = startDate.clone();
            while (currentDate.isSameOrBefore(endDate)) {
                dateArray.push(currentDate.format('MM-DD-YYYY'));
                currentDate.add(1, 'day');
            }
            // console.log('dateArray', dateArray);
            localStorage.setItem('dateArray', JSON.stringify(dateArray));
        }
    } else {
        setIsNextButtonDisabled(true);
    }
};
