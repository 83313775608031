import axios from 'axios';
import { BASE_URL } from './constants';

// const API_BASE_URL = 'https://test-cmolds.com/bizinc-api/public/api'; // Replace with your API base URL
const axiosInstance = axios.create({
  baseURL: BASE_URL,
});


axiosInstance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const get = async (url, params = {}, headers = {}, token) => {
  try {
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    const response = await axiosInstance.get(url, { params, headers });
    return response.data;
  } catch (error) {
    handleRequestError(error);
    throw error;
  }
};

export const post = async (url, data = {}, headers = {}, token) => {
  // console.log("POST_DATA==>", data);
  try {
    if (token) {
      headers['Accept'] = 'application/json';
      headers['Authorization'] = `Bearer ${token}`;
    }
    let config = { headers };

    if (data instanceof FormData) {
      config.headers = { ...headers, 'Content-Type': 'multipart/form-data' };
    }

    const response = await axiosInstance.post(url, data, config);
    return response.data;
  } catch (error) {
    handleRequestError(error);
    throw error;
  }
};

export const put = async (url, data = {}, headers = {}, token) => {
  try {
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    const response = await axiosInstance.put(url, data, { headers });
    return response.data;
  } catch (error) {
    handleRequestError(error);
    throw error;
  }
};

export const patch = async (url, data = {}, headers = {}, token) => {
  try {
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    const response = await axiosInstance.patch(url, data, { headers });
    return response.data;
  } catch (error) {
    handleRequestError(error);
    throw error;
  }
};

export const del = async (url, headers = {}, token) => {
  try {
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    const response = await axiosInstance.delete(url, { headers });
    return response.data;
  } catch (error) {
    handleRequestError(error);
    throw error;
  }
};

const handleRequestError = (error) => {
  console.log("ERROR", error);
  if (axios.isAxiosError(error)) {
    // Axios error (e.g., network error, status code other than 2xx)
    if (error.response) {
      // The request was made, but the server responded with a status code other than 2xx
      console.log("Request failed with status code:", error.response.status);
      console.log("Response data:", error.response.data);
    } else if (error.request) {
      // The request was made, but no response was received
      console.log("No response received. Check your network connection.");
    } else {
      // Something happened in setting up the request that triggered an error
      console.log("Error setting up the request:", error.message);
    }
  } else {
    // Non-Axios error (e.g., network error, CORS issue)
    console.log("Non-Axios error:", error.message);
  }
};

// Other utility functions or configurations can be added here

export default axiosInstance;