import React, { useState, useRef, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import { get } from "../../utils/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { setUseAgreement } from "../../state";
import Toast from "../Toast";

export function Aggrementmodal({
    setAgreementCheck,
    showModal,
    setShowModal,
    agreementData,
    // setSignature,
    // formik,
    isFromSignup,


    // onSubmitSignature,
}) {
    // const dispatch = useDispatch();
    const Token = useSelector((state) => state.token);
    const userData = useSelector((state) => state.user);
    // const [showFullText, setShowFullText] = useState(false);
    // const [signatureSubmitted, setSignatureSubmitted] = useState(false);
    const [onsubmit, setOnSubmit] = useState(null);

    const [setImage, setShowImage] = useState(null);
    // const signatureCanvasRef = useRef(null);
    // const toggleReadMore = () => {
    //     setShowFullText(!showFullText);
    // };

    // const clear = () => {
    //     signatureCanvasRef.current.clear();
    // };

    // const handleSubmission = () => {
    //     if (signatureCanvasRef.current?.isEmpty()) {
    //         Toast({ message: "Signature is required", isError: true });
    //     } else {
    //         setShowModal(false);
    //         if (signatureSubmitted) {
    //             formik.setFieldValue("checkbox", true);
    //             formik.setFieldError("checkbox", "");
    //             onSubmitSignature(onsubmit);
    //         }
    //     }
    // };

    // useEffect(() => {
    //     Token !== null && getBookings();
    // }, []);

    // const getBookings = () => {
    //     get(`/user/user/${userData.id}`, {}, {}, Token)
    //         .then((res) => {
    //             const response = res?.response?.data?.user?.user_agreement?.media;
    //             if (response) {
    //                 setShowImage(response);
    //             } else {
    //                 Toast({ message: "Image URL is missing in the API response", isError: true });
    //             }
    //         })
    //         .catch((err) => {
    //             Toast({ message: err?.response?.data?.error?.messages[0], isError: true });
    //         });
    // };

    // const dataURLtoFile = (dataurl, filename) => {
    //     var arr = dataurl.split(","),
    //         mime = arr[0].match(/:(.*?);/)[1],
    //         bstr = atob(arr[arr.length - 1]),
    //         n = bstr.length,
    //         u8arr = new Uint8Array(n);
    //     while (n--) {
    //         u8arr[n] = bstr.charCodeAt(n);
    //     }
    //     return new File([u8arr], filename, { type: mime });
    // };

    return (
        <>
            {showModal ? (
                <>
                    <div className="justify-center items-center flex fixed inset-0 z-50   ">
                        <div className="lg:w-[50%]    fixed p-5  md:px-20 mx-auto">
                            <div className="border-0 rounded-lg shadow-lg relative md:h-full h-[450px]  flex flex-col w-full bg-white outline-none focus:outline-none ">
                                <div className="flex bg-Appcolor p-5 border-b border-solid border-blueGray-200 rounded-t-lg ">
                                    <h3 className="lg:text-[18px] text-[14px] w-full flex justify-center mx-auto text-white font-medium">
                                        Rental Agreement
                                    </h3>
                                    <button
                                        className="w-0 h-2 rounded-2xl mt-[-35px] mr-[-2px]"
                                        onClick={() => {
                                            {
                                                isFromSignup ? (
                                                    <>
                                                        {/* {clear()} */}
                                                        {setShowModal(false)}
                                                    </>
                                                ) : (
                                                    setShowModal(false)
                                                );
                                            }
                                        }}
                                    >
                                        <span className="bg-white w-8 h-8 rounded-3xl text-black text-2xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                <div className="w-[90%] mx-auto custom-scrollbar xl:h-[600px] md:h-[400px]  ">
                                    <div className="lg:w-[90%] w-[95%] my-10  mx-auto">
                                        <p className="font-semibold text-Textdarkcolor mt-[20px] lg:text-[16px] text-[12px]">
                                            Rentals Reimagined Agreement
                                        </p>
                                        <div className="flex justify-between ">
                                            <div className="lg:text-[14px]   text-[10px]  mt-[18px] w-full text-[Textlitecolor]">
                                            <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: agreementData?.content ?? "",
                                                            }}
                                                        >
                                                         </div>
                                                {/* {showFullText ? (
                                                    <>
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: agreementData?.content ?? "",
                                                            }}
                                                        >
                                                         </div>
                                                        <button
                                                            className="text-blue-500 flex cursor-pointer"
                                                            onClick={toggleReadMore}
                                                        >
                                                            <br />
                                                            <p className=" lg:mt-[30px] mt-[15px] ">
                                                                {" "}
                                                                Read Less...
                                                            </p>
                                                        </button>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html:
                                                                    agreementData?.content?.length > 400
                                                                        ? agreementData?.content.substr(0, 1000) +
                                                                        " ..."
                                                                        : agreementData?.content,
                                                            }}
                                                        ></div>
                                                        <button
                                                            className="text-blue-500 flex cursor-pointer"
                                                            onClick={toggleReadMore}
                                                        >
                                                            <br />
                                                            <p className="lg:mt-[25px] mt-[15px]">
                                                                Read More
                                                            </p>
                                                        </button>
                                                    </>
                                                )} */}
                                            </div>
                                            {/* {isFromSignup && (
                                                <div className="mt-auto">
                                                    <button
                                                        className="text-blue-500 flex cursor-pointer "
                                                        // onClick={clear}
                                                        onClick={() => {
                                                            clear();
                                                            setSignatureSubmitted(false);
                                                        }}
                                                    >
                                                        <br />
                                                        <p className="lg:mt-[30px] mt-auto lg:text-[14px]   text-[10px]">
                                                            Clear
                                                        </p>
                                                    </button>
                                                </div>
                                            )} */}
                                        </div>
                                    </div>

                                    {/* <div className="">
                                        {isFromSignup ? (
                                            <SignatureCanvas
                                                penColor="black"
                                                canvasProps={{
                                                    className:
                                                        "mx-auto md:h-[250px] h-[140px] w-[90%] rounded-md shadow-[0_0px_60px_2px_rgba(0,0,0,0.05)] md:mt-8 mt-5",
                                                }}
                                                ref={signatureCanvasRef}
                                                onEnd={() => {
                                                    if (signatureCanvasRef.current) {
                                                        let img = signatureCanvasRef.current.toDataURL();
                                                        setOnSubmit(dataURLtoFile(img, "signature.png"));
                                                        setSignatureSubmitted(true);

                                                        // setSignature(dataURLtoFile(img, 'signature.png'));
                                                    }
                                                }}
                                            />
                                        ) : (
                                            <div className="w-[90%] mx-auto flex justify-center items-center mt-10">
                                                <img
                                                    draggable={false}
                                                    src={setImage}
                                                    alt="Signature Image"
                                                    className="w-[100%] h-[100%]"
                                                />
                                            </div>
                                        )}
                                    </div>
                                    {isFromSignup ? (
                                        <div className="flex items-center lg:mt-[10px] mt-[15px] justify-center p-6 rounded-b">
                                            <button
                                                className={`${signatureSubmitted ? "bg-Appcolor" : "bg-gray-400"
                                                    }  text-white font-medium px-2 py-3 rounded-md w-[240px] text-sm`}
                                                type="button"
                                                disabled={!signatureSubmitted}
                                                onClick={() => {
                                                    setAgreementCheck(true);
                                                    // dispatch(setUseAgreement(true));
                                                    setShowModal(false);
                                                    handleSubmission(onsubmit);
                                                    // console.log("onsubmit", onsubmit);
                                                }}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    ) : null} */}


                                         <div className="flex items-center lg:mt-[10px] mt-[15px] justify-center p-6 rounded-b">
                                            <button
                                                className={`bg-Appcolor text-white font-medium px-2 py-3 rounded-md w-[240px] text-sm`}
                                                type="button"
                                                 onClick={() => {
                                                    setAgreementCheck(true);
                                                    // dispatch(setUseAgreement(true));
                                                    setShowModal(false);
                                                    // handleSubmission(onsubmit);
                                                    // console.log("onsubmit", onsubmit);
                                                }}
                                            >
                                                Continue
                                            </button>
                                        </div>
 



                                    {/*  */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    );
}

















// import React, { useState, useRef, useEffect } from "react";
// import SignatureCanvas from "react-signature-canvas";
// import { get } from "../../utils/axiosInstance";
// import { useDispatch, useSelector } from "react-redux";
// import { setUseAgreement } from "../../state";
// import Toast from "../Toast";

// export function Aggrementmodal({
//     setAgreementCheck,
//     showModal,
//     setShowModal,
//     // agreementData,
//     // setSignature,
//     // formik,
//     // isFromSignup,


//     // onSubmitSignature,
// }) {
//     // const dispatch = useDispatch();
//     // const Token = useSelector((state) => state.token);
//     // const userData = useSelector((state) => state.user);
//     // const [showFullText, setShowFullText] = useState(false);
//     // const [signatureSubmitted, setSignatureSubmitted] = useState(false);
//     // const [onsubmit, setOnSubmit] = useState(null);

//     // const [setImage, setShowImage] = useState(null);
//     // const signatureCanvasRef = useRef(null);
//     // const toggleReadMore = () => {
//     //     setShowFullText(!showFullText);
//     // };

//     // const clear = () => {
//     //     signatureCanvasRef.current.clear();
//     // };

//     // const handleSubmission = () => {
//     //     if (signatureCanvasRef.current?.isEmpty()) {
//     //         Toast({ message: "Signature is required", isError: true });
//     //     } else {
//     //         setShowModal(false);
//     //         if (signatureSubmitted) {
//     //             formik.setFieldValue("checkbox", true);
//     //             formik.setFieldError("checkbox", "");
//     //             onSubmitSignature(onsubmit);
//     //         }
//     //     }
//     // };

//     // useEffect(() => {
//     //     Token !== null && getBookings();
//     // }, []);

//     // const getBookings = () => {
//     //     get(`/user/user/${userData.id}`, {}, {}, Token)
//     //         .then((res) => {
//     //             const response = res?.response?.data?.user?.user_agreement?.media;
//     //             if (response) {
//     //                 setShowImage(response);
//     //             } else {
//     //                 Toast({ message: "Image URL is missing in the API response", isError: true });
//     //             }
//     //         })
//     //         .catch((err) => {
//     //             Toast({ message: err?.response?.data?.error?.messages[0], isError: true });
//     //         });
//     // };

//     // const dataURLtoFile = (dataurl, filename) => {
//     //     var arr = dataurl.split(","),
//     //         mime = arr[0].match(/:(.*?);/)[1],
//     //         bstr = atob(arr[arr.length - 1]),
//     //         n = bstr.length,
//     //         u8arr = new Uint8Array(n);
//     //     while (n--) {
//     //         u8arr[n] = bstr.charCodeAt(n);
//     //     }
//     //     return new File([u8arr], filename, { type: mime });
//     // };

//     return (
//         <>
//             {showModal ? (
//                 <>
//                     <div className="justify-center items-center flex fixed inset-0 z-50   ">
//                         <div className="lg:w-[50%]    fixed p-5  md:px-20 mx-auto">
//                             <div className="border-0 rounded-lg shadow-lg relative md:h-full h-[450px]  flex flex-col w-full bg-white outline-none focus:outline-none ">
//                                 <div className="flex bg-Appcolor p-5 border-b border-solid border-blueGray-200 rounded-t-lg ">
//                                     <h3 className="lg:text-[18px] text-[14px] w-full flex justify-center mx-auto text-white font-medium">
//                                         Rental Agreement
//                                     </h3>
//                                     <button
//                                         className="w-0 h-2 rounded-2xl mt-[-35px] mr-[-2px]"
//                                         // onClick={() => {
//                                         //     {
//                                         //         isFromSignup ? (
//                                         //             <>
//                                         //                 {/* {clear()} */}
//                                         //                 {setShowModal(false)}
//                                         //             </>
//                                         //         ) : (
//                                         //             setShowModal(false)
//                                         //         );
//                                         //     }
//                                         // }}
//                                     >
//                                         <span className="bg-white w-8 h-8 rounded-3xl text-black text-2xl block outline-none focus:outline-none">
//                                             ×
//                                         </span>
//                                     </button>
//                                 </div>
//                                 <div className="w-[90%] mx-auto custom-scrollbar xl:h-[600px] md:h-[400px]  ">
//                                     <div className="lg:w-[90%] w-[95%] my-10  mx-auto">
//                                         <p className="font-semibold text-Textdarkcolor mt-[20px] lg:text-[16px] text-[12px]">
//                                             Rentals Reimagined Agreement
//                                         </p>
//                                         <div className="flex justify-between ">
//                                             <div className="lg:text-[14px]   text-[10px]  mt-[18px] w-full text-[Textlitecolor]">
//                                             {/* <div
//                                                             dangerouslySetInnerHTML={{
//                                                                 __html: agreementData?.content ?? "",
//                                                             }}
//                                                         >
//                                                          </div> */}
                                                
//                                             </div>
                                          
//                                         </div>
//                                     </div>

                                  


//                                          <div className="flex items-center lg:mt-[10px] mt-[15px] justify-center p-6 rounded-b">
//                                             <button
//                                                 className={`bg-Appcolor text-white font-medium px-2 py-3 rounded-md w-[240px] text-sm`}
//                                                 type="button"
//                                                  onClick={() => {
//                                                     setAgreementCheck(true);
//                                                     // dispatch(setUseAgreement(true));
//                                                     setShowModal(false);
//                                                     // handleSubmission(onsubmit);
//                                                     // console.log("onsubmit", onsubmit);
//                                                 }}
//                                             >
//                                                 Continue
//                                             </button>
//                                         </div>
 



//                                     {/*  */}
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
//                 </>
//             ) : null}
//         </>
//     );
// }


