import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate, useNavigate, Link } from "react-router-dom";
import Signup from "../src/components/auth/Signup";
import Home from "../src/components/Homepage";
import Login from "../src/components/auth/Login";
import HelpSupport from "../src/components/Helpsupport";
import Settings from "../src/components/Settings";
import ForgotPassword from "../src/components/auth/Forgotpass";
import Reservations from "../src/components/Reservation/index";
import Vendors from "../src/components/Vendors/index";
import { useDispatch, useSelector } from "react-redux";
import { setUseJourneyVendor } from "./state";

function App() {
  const dispatch = useDispatch();
  const Auth = Boolean(useSelector((state) => state.token));
  const userType = useSelector((state) => state.user_type);
  const useJourneyVendor = Boolean(useSelector((state) => state.useJourneyVendor));


  // useEffect(() => {

  //   if (Auth) {
  //     <Link to={<Home />} ></Link>
  //   }
  // }, [])



  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get("vi");
  useEffect(() => {
    if (id) {
      dispatch(setUseJourneyVendor(id));
    }
  }, []);




  // console.log(useJourneyVendor);
  // console.log(userType);
  return (
    <div className="app">
      <BrowserRouter>
        <Routes>
          <Route path="/"

            element={!Auth ? <Login /> : userType === "guest" ? <Navigate to="/home" /> : <Navigate to="/vendors" />}
          />
          <Route path="/signup"
            element={!Auth ? <Signup /> : userType === "guest" ? <Navigate to="/home" /> : <Navigate to="/vendors" />}
          />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          {
            useJourneyVendor == null ? <Route
              path="/from-vendor"
              element={Auth ? <Home /> : <Navigate to="/" />}
            /> : <Route path="/home" element={Auth ? userType === "guest" ? <Home /> : <Vendors /> : <Navigate to="/" />} />
          }
          <Route
            path="/vendors"
            element={Auth ? userType === "guest" ? <Home /> : <Vendors /> : <Navigate to="/" />}
          />
          <Route
            path="/helpsupport"
            element={Auth ? <HelpSupport /> : <Navigate to="/" />}
          />
          <Route
            path="/settings"
            element={Auth ? <Settings /> : <Navigate to="/" />}
          />
          <Route
            path="/reservations"
            element={Auth ? <Reservations /> : <Navigate to="/" />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;
