import React, { useState, useEffect } from 'react';
import Reservationsidecard from './Reservationsidecard';
import Detailcard from './Detailcard';
import { get } from '../../utils/axiosInstance';
import { useSelector } from 'react-redux';
import Toast from '../Toast';

const Reservationcard = () => {
    const [selectedReservation, setSelectedReservation] = useState(null);
    const [reservations, setReservations] = useState([]);
    const [reservationsById, setReservationsById] = useState({});
    const [loader, setLoader] = useState(false);
    const Token = useSelector((state) => state.token);

    useEffect(() => {
        setLoader(true);
        getAllBookings();
    }, []);

    const getAllBookings = () => {
        setLoader(true);
        // const Token = localStorage.getItem("token");
        get("/booking/bookings", {}, {}, Token)
            .then((res) => {
                setLoader(false);
                const reservationData = res?.response?.data?.bookings;
                setReservations(reservationData);
            })
            .catch((err) => {
                Toast({ message: err.response.data.error.messages[0], isError: true });
            })
    };

    const handleReservationClick = (id) => {
        const clickedReservation = reservations?.find((reservation) => reservation.id === id);
        setSelectedReservation(clickedReservation);
        getBookingsbyId(clickedReservation);
    };

    const getBookingsbyId = (selectedReservation) => {
        if (selectedReservation) {
            setLoader(true);
            const selectedId = selectedReservation.id;
            // const Token = localStorage.getItem("token");

            get(`/booking/booking/${selectedId}`, {}, {}, Token)
                .then((res) => {
                    setLoader(false);
                    const reservationIdData = res?.response?.data.booking_history;
                    setReservationsById(reservationIdData);
                })
                .catch((err) => {
                    Toast({ message: err.response.data.error.messages[0], isError: true });
                })
        }
    };


    return (
        <div className="w-[100%] lg:py-8 pt-4 flex lg:flex-row flex-col justify-center items-center bg-Btncolor">
            <div className="lg:w-[30%] w-[90%] h-[100%]">
                <Reservationsidecard
                    handleReservationClick={handleReservationClick}
                    reservations={reservations ?? []}
                    loader={loader}
                />
            </div>
            <div className="lg:w-[60%] w-[90%] lg:my-0 my-10 flex items-center justify-center flex-col">
                {/* {console.log("reservationsById", reservationsById)} */}
                {Object.keys(reservationsById).length > 0 && <Detailcard apiData={reservationsById} />}
            </div>
            {/* <ToastContainer limit={1} /> */}
        </div>
    );
};

export default Reservationcard;