import React,{useState} from "react";
import moment from "moment";
import { SummaryAggrementmodal } from "../Modals/SummaryAggrementmodal";

const Reservationdatacard = ({ apiData }) => {
    const summaryData = apiData?.tax_and_charges.map((charge) => ({
        heading: charge.category,
        text: charge.name,
        value: charge.value,
        type: charge.operation_type
    }));
    const start = moment(apiData.start_date).format('MM/DD/YYYY');
    const end = moment(apiData.end_date).format('MM/DD/YYYY');
    const startDate = moment(start, 'MM/DD/YYYY');
    const endDate = moment(end, 'MM/DD/YYYY');
    const daysDifference = endDate.diff(startDate, 'days') + 1;

    const [showModal, setShowModal] = useState(false);
    const [agreementCheck, setAgreementCheck] = useState(false);
    const [responseSignatureData, setResponseSignatureData] = useState(null);
    const [responseData, setResponseData] = useState(null);


    const handleResponseSignatureData = (signatureData) => {
        setResponseSignatureData(signatureData);
        console.log("signatureData", signatureData);
      };

      const handleResponseData = (data) => {
        setResponseData(data);
      };
      const openModal = () => {
        setShowModal(true);
      };


    console.log("summaryData", summaryData);
    // const Value = [
    //     {
    //         title: "Payment Method",
    //         content: apiData && apiData?.bookings[0]?.payment_method,
    //     },
    //     {
    //         title: "Coupon",
    //         content: apiData && apiData?.coupon?.description,
    //     },
    //     {
    //         title: "Address",
    //         content: apiData && apiData?.bookings[0]?.address_details,
    //     },
    //     {
    //         title: "Main Reservation Date",
    //         // content: `${start_date.format("DD MMM YYYY")} - ${end_date.format("DD MMM YYYY")}`,
    //     },
    // ];
 
    localStorage.setItem("apiData",apiData.signature)

    let discount = apiData?.discounts;
    let duration = 0
    let coupon = apiData?.coupon

    const checkType = (item) => {

        return item.type === "percent" ? `${item.value}%` : `$${item.value}`
    }
    const getDuration = () => {
        const durationValues = apiData?.booking_details.map(item => parseInt(item.duration, 10));
        return durationValues.reduce((sum, duration) => sum + duration, 0);
    }
    return (
        <>
            <div className="my-4 py-4">
                <div className={`bg-white-500 rounded-xl shadow-[0px_8px_32px_#00000014] mx-4 py-4 border-black-600 text-center`}  >
                    <div>
                        <>
                            <p className="text-Textdarkcolor flex  px-5 text-[16px]">Cart Vendor</p>
                            <div className={`justify-start flex flex-col text-start p-5`}>
                                <p className=" md:text-[16px] text-[14px]">Title: <span className="text-Appcolor py-3  md:text-[16px] text-[1
                                    3px]">{apiData.vendor?.title}</span></p>
                                <p className="my-3  md:text-[16px] text-[14px]">Location: <span className="text-Appcolor  md:text-[16px] text-[13px] first-letter:">{apiData.vendor?.location}</span></p>

                                <p className=" md:text-[16px] text-[14px]">Description: <span className="text-Appcolor  md:text-[16px] text-[13px]"
                                    dangerouslySetInnerHTML={{
                                        __html: apiData.vendor?.description ?? "",
                                    }}
                                >
                                </span></p>
                            </div>
                        </>
                    </div>
                </div>
            </div>
            <div className="rounded-xl bg-[#00000014] text-center shadow-[0px_8px_32px_#00000014] border-black-600 mx-auto w-[95%]">
                <div className="flex pt-[24px] w-[90%] md:w-[90%] mx-auto justify-between md:text-[16px] text-[13px] ">
                    <p className=" ">Total Selected Day(s)</p>
                    {/* <p className="">{daysDifference}</p> */}
                    <p className="">{getDuration()}</p>
                </div>

                <div className="flex flex-col  md:text-[16px] text-[13px] w-[90%] mx-auto">
                    <div className="flex md:pt-[24px] pt-[10px] font-bold text-[#e6a62f] justify-between">
                        <p className="">Sub Total</p>
                        <p className="">${apiData.sub_amount}</p>
                    </div>
                    <p className="flex md:pt-[24px] pt-[14px] font-bold text-[#e6a62f] justify-start">
                        Tax And Charges Details
                    </p>
                </div>

                <div className="flex outline-0 flex-col md:text-[16px] text-[13px] w-[90%] mx-auto">
                    {summaryData.map((item, ind) => {
                        return (

                            <div className="flex md:pt-[18px] pt-[6px] flex-col" key={ind}>
                                <div className="flex justify-between items-center">
                                    <p className="text-start font-bold text-[#e6a62f]">{item.heading}</p>
                                    {item.value !== null && (
                                        <>
                                            {/* {item.operation_type === "percent" ?  <p className="font-bold text-[#e6a62f]">{item.value}%</p> : null} */}
                                            <p className="font-bold text-[#e6a62f]">{checkType(item)}</p>
                                        </>
                                    )}

                                </div>
                                <p className="text-start text-Textlitecolor">{item.text}</p>
                            </div>

                        )
                    })}
                </div>
                {/* {
                    apiData.discounts !== null ? (
                        <div className="flex outline-0 flex-col text-[16px] w-[90%] mx-auto pt-1">
                            <div className="flex justify-between text-[#e6a62f] py-3 items-center font-semibold">
                                <div className="text-start">
                                    <p>Discount</p>
                                    <p className="text-start text-Textlitecolor font-normal">{apiData?.discounts.name}</p>
                                </div>
                                {discount.value && (
                                    <p className="text-[#e6a62f] font-semibold">
                                        $
                                        {
                                            (discount =
                                                discount.operation_type === "percent"
                                                    ? ((apiData.sub_amount * discount.value) / 100).toLocaleString('en-US')
                                                    : discount.value.toLocaleString('en-US'))
                                        }
                                    </p>
                                )}
                            </div>
                        </div>
                    ) : <p></p>
                }

                {
                    apiData.coupon !== null && (
                        <div className="flex outline-0 flex-col text-[16px] w-[90%] mx-auto pt-1">
                            <div className="flex justify-between text-[#e6a62f] py-3 items-center font-semibold">
                                <div className="text-start">
                                    <p>Coupon</p>
                                </div>
                                {coupon.value && (
                                    <p className="text-[#e6a62f] font-semibold">
                                        $
                                        {
                                            (coupon = coupon.operation === "percentage"
                                                ? ((apiData.sub_amount * coupon.value) / 100).toLocaleString('en-US')
                                                : coupon.value.toLocaleString('en-US'))
                                        }
                                    </p>
                                )}
                            </div>
                        </div>
                    )
                } */}

                {
                    apiData.discounts !== null ? (
                        <div className="flex outline-0 flex-col text-[16px] w-[90%] mx-auto md:pt-1">
                            <div className="flex justify-between text-[#e6a62f] py-3 items-center font-semibold">
                                <div className="text-start">
                                    <p>Discount</p>
                                    <p className="text-start text-Textlitecolor font-normal">{apiData?.discounts.name}</p>
                                </div>
                                {discount.value && (
                                    <p className="text-[#e6a62f] font-semibold">
                                        $
                                        {
                                            (discount =
                                                discount.operation_type === "percent"
                                                    ? ((apiData.sub_amount * discount.value) / 100).toLocaleString('en-US')
                                                    : discount.value.toLocaleString('en-US'))
                                        }
                                    </p>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className="flex outline-0 flex-col text-[16px] w-[90%] mx-auto md:pt-1">
                            {/* <div className="flex justify-between text-[#e6a62f] py-3 items-center font-semibold">
                                <div className="text-start">
                                    <p>Discount</p>
                                </div>
                                <p className="text-[#e6a62f] font-semibold"> $0 </p>
                            </div> */}
                        </div>
                    )
                }
                {
                    apiData.coupon !== null ? (
                        <div className="flex outline-0 flex-col text-[16px] w-[90%] mx-auto md:pt-1">
                            <div className="flex justify-between text-[#e6a62f] py-3 items-center font-semibold">
                                <div className="text-start">
                                    <p>Coupon</p>
                                </div>
                                {coupon.value && (
                                    <p className="text-[#e6a62f] font-semibold">
                                        $
                                        {
                                            coupon.operation === "percentage"
                                                ? ((apiData.sub_amount * coupon.value) / 100).toLocaleString('en-US')
                                                : coupon.value.toLocaleString('en-US')
                                        }
                                    </p>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className="flex outline-0 flex-col text-[16px] w-[90%] mx-auto pt-1">
                            {/* <div className="flex justify-between text-[#e6a62f] py-3 items-center font-semibold">
                                <div className="text-start">
                                    <p>Coupon</p>
                                </div>
                                <p className="text-[#e6a62f] font-semibold"> $0 </p>
                            </div> */}
                        </div>
                    )
                }



                {/* {
                    apiData.coupon !== null && (
                        <div className="flex outline-0 flex-col text-[16px] w-[90%] mx-auto pt-1">
                            <div className="flex justify-between text-[#e6a62f] py-3 items-center font-semibold">
                                <p>Coupon</p>

                                <p>${apiData?.coupon.value}</p>
                            </div>
                        </div>
                    )
                } */}
                <div className="flex outline-0 flex-col md:text-[16px] text-[14px] w-[90%] mx-auto md:pt-5">
                    <div className="flex justify-between text-[#e6a62f] py-3 items-center font-semibold">
                        <p>Total</p>
                        <p>${apiData?.total_amount}</p>
                    </div>
                </div>
            </div>
            <div className="w-[90%] mx-auto my-5">
  <div className="mt-5">
    <div className="flex">
      <input
        id={1}
        name={"checkbox"}
        checked={true}  
        type={"checkbox"}
        placeholder={"checkbox"}
        readOnly  
      />
      <label htmlFor={""} className={"pl-3 md:text-[16px] text-[12px] text-Textdarkcolor font-medium"}>
        <span>
          View the Vendor's
          <a
            onClick={  openModal  }
            className={`underline cursor-pointer pl-1 text-Appcolor hover:text-blue-600 visited:text-purple-600 `}
          >
            Agreement.
          </a>
        </span>
      </label>
    </div>
  </div>
</div>


        <SummaryAggrementmodal
          showModal={showModal}
          setShowModal={setShowModal}
           setAgreementCheck={setAgreementCheck}
          onResponseData={handleResponseData}
          onResponseSignatureData={handleResponseSignatureData}
        />
        </>
    );
};

export default Reservationdatacard;


