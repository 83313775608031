import React from "react";
import { Loginvalidationschema } from "./schema";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
export const KEY_STRIPE_LIVE =
  "pk_live_51MHZZoEUMHjNjlrqV5T6WIPM55zgcf9Mcgw9svz1mHzDegW0JUmuZilP1aBLtZdnuqPRuXyHweozJTFx6TEMu5B2006W6Uby4S";

export const KEY_STRIPE_DEMO =
  "pk_test_51L5S2aAEo4X1hkOdT3R5XqvOyUHogpktqCwDBW2dWF1mXPgjwRrcNIIbnpKN46mkXCN5523wUlJ1jUOIgAMFdiJY00eNjx5ljG";

export const KEY_STRIPE_LOCAL =
  "pk_test_51MHZZoEUMHjNjlrqJ0SkAVflDjIvaFPPSh8HiVQZGzgWQM9ctFidam587UylVyc11cB2hh6Ga23BQcuPSTJ2eOIm00GY55v4gg";
  
// export const BASE_URL = "https://rentalsreimagined.com/api-v3/public/api";

// export const BASE_URL = "https://rentalsreimagined.com/api-live/public/api";
export const BASE_URL = "https://test-cmolds.com/rentals-api-v3/public/api";


export const Loginconstant = () => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      firstname: "",
      email: "",
    },
    validationSchema: Loginvalidationschema,
    onSubmit: (values) => {
      navigate("/home");
    },
  });

  return <div></div>;
};

export const isPointInPolygon = (point, polygon) => {
  let inside = false;
  const x = point.lat;
  const y = point.lng;

  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const xi = polygon[i].lat;
    const yi = polygon[i].lng;
    const xj = polygon[j].lat;
    const yj = polygon[j].lng;

    const intersect =
      yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;

    if (intersect) {
      inside = !inside;
    }
  }
  return inside;
};

//   export const isPointInPolygon = (point, polygon) => {
//     let isInside = false;
//     let i, j = polygon.length - 1;
//     console.log('====================================');
//     console.log(point);
//     console.log('====================================');
//     console.log('====================================');
//     console.log(polygon);
//     console.log('====================================');
//     for (i = 0; i < polygon.length; i++) {
//       const { lat: x1, lng: y1 } = polygon[i];
//       const { lat: x2, lng: y2 } = polygon[j];
//       const onBoundary = (
//         ((point.lng <= y1 && point.lng >= y2) || (point.lng <= y2 && point.lng >= y1)) &&
//         (point.lat <= x1 || point.lat <= x2) &&
//         (y1 + (point.lat - x1) / (x2 - x1) * (y2 - y1)) <= point.lng
//       );
//       if (onBoundary) return true;
//       if ((x1 < point.lat && x2 >= point.lat) || (x2 < point.lat && x1 >= point.lat)) {
//         if (y1 + (point.lat - x1) / (x2 - x1) * (y2 - y1) < point.lng) {
//           isInside = !isInside;
//         }
//       }
//       j = i;
//     }
//     return isInside;
//   };
 

export const Images = {
  Step1: require("../assets/Png/Step1.png"),
  Step2: require("../assets/Png/Step2.png"),
  Step3: require("../assets/Png/Step3.png"),
  Step4: require("../assets/Png/Step4.png"),
  Step5: require("../assets/Png/Step5.png"),
  Step6: require("../assets/Png/Step6.png"),
  Step7: require("../assets/Png/Step7.png"),
  Step8: require("../assets/Png/Step8.png"),
  Step9: require("../assets/Png/Step9.png"),
  Step10: require("../assets/Png/Step10.png"),
  Step11: require("../assets/Png/Step11.png"),
};


export function getCardToken(data) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${KEY_STRIPE_DEMO}`);
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  const expiry = data.card_expiry_date?.substring(0, 2);
  const expiry2 = data.card_expiry_date?.substring(3, 5);
  const details = {
    "card[name]": data.card_holder_name,
    "card[number]": data.card_number,
    "card[exp_month]": expiry,
    "card[exp_year]": expiry2,
    "card[cvc]": data.card_cvv,
  };

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formUrlEncode(details),
  };

   return fetch(`https://api.stripe.com/v1/tokens`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      return result.id;
    })
    .catch((err) => console.log(err));
}

export function formUrlEncode(payload) {
  let formBody = [];
  for (var property in payload) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(payload[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }

  return formBody.join("&");
}

export const formatCardNumber = value => {
  return value
    .replace(/\s/g, '')
    .replace(/(.{4})/g, '$1 ')
    .trim();
};

export const formatExpiryDate = value => {
  const cleanedValue = value.replace(/\D/g, '');
  return cleanedValue.replace(/(.{2})/, '$1/');
};