import React from 'react';
import Vehicle from '../../assets/Vehicle.svg'
import Inputfield from '../Inputfiled';
import PasswordInput from '../Password';
import Button from '../Button';
import { Changepasswordschema } from '../../utils/schema';
import { useFormik } from 'formik';
import { post } from '../../utils/axiosInstance';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const ChangePassword = () => {

    const formik = useFormik({
        initialValues: {
            current_password: '',
            password: '',
            password_confirmation: ''
        },
        validationSchema: Changepasswordschema,
        onSubmit: (values) => {
            handleSubmit({ ...values })
            console.log("current_password" + values.current_password);
            console.log(values);
        },
    });

    const handleSubmit = (e) => {
        const Token = localStorage.getItem('token')
        console.log('Token' + Token);

        post('/user/change-password', e, {}, Token)
            .then((res) => {
                console.log("Changepassword Res " + res);
                const Response = res.response.data
                console.log("Response Res " + res.response.data);


                if (Response && 'response' in res) {
                    toast.success("Password Changed Successfully", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                } else {
                    console.log("ChangeRes " + Response);
                    toast.error("Password Not Change", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            })
            .catch((err) => {
                toast.error(err.response.data.error.messages[0], {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            })


    }
    return (
        <div className='bg-[#FFFFFF]  rounded-[10px]  flex flex-col   items-center w-[100%] lg:h-[670px] md:h-[500px] h-[360px]'>
            <ToastContainer limit={2} />
            {/* <div className='flex justify-start border-b-[1px] border-[#EDF0F6] py-[20px]  w-[90%]'> */}
            <p className=' md:mt-[27px] mt-[15px] md:text-[22px] text-[16px] font-bold text-Textdarkcolor w-[90%]'>Change Password</p>
            {/* </div> */}

            <form className='p-3 lg:w-[95%] w-[90%] custom-scrollbar' onSubmit={formik.handleSubmit}>
                <div className=' lg:w-[70%] w-[90%]   flex flex-col justify-start lg:p-4 px-4 pt-0 pb-0'>
                    <PasswordInput
                        labelstyle="md:mt-[41px] mt-[10px] lg:text-[20px] text-[13px]"
                        label="Current Password"
                        name="current_password"
                        placeholder="Enter Current Password"
                        value={formik.values.current_password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.errors.current_password}
                        touched={formik.touched.current_password}

                    />
                    <PasswordInput
                        labelstyle="lg:mt-[41px] mt-[10px] lg:text-[20px] text-[13px]"
                        label="New Password"
                        name="password"
                        placeholder="Enter New Password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.errors.password}
                        touched={formik.touched.password}
                    />
                    <PasswordInput
                        labelstyle="lg:mt-[41px] mt-[10px]  lg:text-[20px] text-[13px]"

                        label="Confirm Password"
                        name="password_confirmation"
                        placeholder="Enter Confirm Password"
                        value={formik.values.password_confirmation}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.errors.password_confirmation}
                        touched={formik.touched.password_confirmation}
                    />

                    <div>
                        <Button divstyle="button lg:w-[40%] w-[100%] md:mt-[86px] mt-[15px] md:p-3 p-2 shadow-[0_5px_40px_-20px_#0075FF] text-white font-medium md:text-[20px] text-[16px]" btnname="Save Password" type='submit' />
                    </div>


                </div>
            </form>

        </div >
    );
};

export default ChangePassword;