import React from "react";
import Logo from "../../assets/Logo.svg";
import Googleplay from "../../assets/Png/Googleplay.png";
import Appstore from "../../assets/Png/Applestore.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Qrcode from "../../assets/Png/Qrcode.png";

export default function Successmodal({
  successModal,
  setShowsuccessModal,
  switchComponent,
  goToNextStep,
}) {
  const userType = useSelector((state) => state.user_type);

  const user = useSelector((state) => state.user);

  const selectedVendor = useSelector((state) => state.selectedVendor);


// yahan vendor nhi araha to title kese mile ga 

  const title = selectedVendor?.title ?? user?.default_vendor?.title

 

  const handleFinish = () => {
    setShowsuccessModal(false);
    window.location.reload();
  };

  return (
    <>
      {successModal ? (
        <>
          <div className="justify-center items-center    fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="lg:w-[35%] w-[65%] lg:py-20 my-7 mx-auto">
              <div className="border-0 rounded-lg shadow-lg  relative flex flex-col bg-white outline-none focus:outline-none">
                <div className="flex p-5 rounded-t ml-auto">
                  <button className="w-0 h-0 mt-[-35px]" onClick={handleFinish}>
                    <span className="bg-gray-100  w-8 h-auto rounded-2xl text-black text-[22px] pt-[-39px] block ">
                      ×
                    </span>
                  </button>
                </div>

                <div>
                  <div className="w-[80%] mx-auto text-center">
                    <img
                      className="lg:w-[200px] w-[160px] mx-auto"
                      src={Logo}
                      alt="right image"
                    />
                    <div className="lg:mt-[27px]  mt-[12px]   flex flex-col text-[26px] leading-[39px] font-semibold">
                      <h1 className="lg:text-[35px] text-[22px]">
                        Congratulations!
                      </h1>
                      <span className="lg:text-[26px] lg:mt-[21px] mt-[2px] text-[18px] text-[#1D2733]">
                        {userType === "guest"
                          ? `Your reservation has been completed Successfully with ${title}`
                          : "Your cart has been created Successfully"}
                      </span>
                    </div>
                    <div className=" lg:mt-[21px] mt-[2px] w-[90%] mx-auto text-[20px] text-[Textlitecolor]">
                      {userType === "guest"
                        ? `Please download the mobile app to activate/deactivate the LSV and utilize advanced features like navigation.`
                        : "Thank you for registering your LSV.  We will contact you shortly to complete the process."}
                    </div>
                  </div>
                  <div className="lg:w-[50%] mx-auto my-1">
                    <img src={Qrcode} className=" w-[45%] mx-auto" />
                  </div>
                  <div className="grid place-items-center md:grid-cols-2 grid-cols-1 my-3 md:gap-[20px] gap-[10px] lg:w-[50%] mx-auto justify-center rounded-b ">
                    <Link to="https://apps.apple.com/pk/app/rentals-reimagined/id1672577637">
                      <img
                        className="w-[150px]"
                        src={Appstore}
                        alt="left image"
                      />
                    </Link>
                    <Link to="https://play.google.com/store/apps/details?id=com.app.ridewiserentalsreimagined">
                      <img
                        className="w-[160px]"
                        src={Googleplay}
                        alt="right image"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
