import React, { useState, useEffect } from 'react';
import { get } from '../../utils/axiosInstance';
import Spinner from '../Spinner';
import { useSelector } from 'react-redux';

const Privacypolicy = () => {
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(false);
    const Token = useSelector((state) => state.token);
    const targetName = "Privacy Policy";

    useEffect(() => {
        setLoader(true);
        // const Token = localStorage.getItem("token");

        get(`/content/contents`, {}, {}, Token)
            .then((res) => {
                setLoader(false);
                const response = res.response.data.contents;
                setData(response || []);
            })
            .catch((err) => {
                setLoader(false);
                console.error("Error fetching data:", err);
            })
            .finally(() => {
                setLoader(false);
            });
    }, []);


    const targetItem = data.find((item) => item.name === targetName);


    if (loader) {
        return (
            <div className="flex justify-center items-center w-full lg:h-[670px] md:h-[670px] h-[650px]">
                <Spinner className="spinner" isLoading={loader} />

            </div>
        );
    } else {
        return (
            <div className='bg-[#FFFFFF] rounded-[10px]  flex flex-col justify-center items-center w-[100%] lg:h-[670px] h-[360px]'>
                <p className='border-b-[1px] border-[#EDF0F6] md:mt-[27px] mt-[15px] md:text-[22px] text-[16px] font-bold text-Textdarkcolor w-[95%]'>Privacy Policy</p>
                <div className='p-4 w-[90%] custom-scrollbar h-[100%]'>
                    {
                        targetItem && (
                            <div key={targetItem.id}>
                                <div className='flex py-3 items-center w-[80%]'>
                                    <span className='w-0 h-[22px] px-[2px] rounded-[0px_8px_8px_0px] bg-[#30559C]'></span>
                                    <p className='pl-2 text-[18px] font-semibold text-Textdarkcolor'>
                                        {targetItem.name}
                                    </p>
                                </div>
                                <p className='text-[#999B9F] lg:text-[16px] text-[12px]' dangerouslySetInnerHTML={{ __html: targetItem.description ?? "" }}></p>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
};

export default Privacypolicy;
