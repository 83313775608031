import React, { useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import "../../../src/index.css";
import Calender from "../Calender";
import Spinner from "../Spinner";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import Toast from "../Toast";
import moment from "moment";
import { SummaryAggrementmodal } from "../Modals/SummaryAggrementmodal";
import { ToastContainer } from "react-toastify";

const Booking = ({ switchComponent, goToNextStep, goToPreviousStep }) => {
  const [showToast, setShowToast] = useState(false); // State to control showing toast

  const bookDates = useSelector((state) => state.bookedDates);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);
  const [bookedDates, setBookedDates] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
  const assigned_cart = useSelector((state) => state.assigned_cart);
  const user = useSelector((state) => state.user);
  const selectedVendor = useSelector((state) => state.selectedVendor);


  const hasBookingDates = [];
  const setValues = (values) => setBookedDates(values);
  const onDateClick = (ranges) => ranges;

  const handleBackClick = () => {
    switchComponent("Searchaddress");
    goToPreviousStep();
    setSelectedDates([]);
  };

  useEffect(() => {
    const hasSelectedDateInRange = () => {
      if (selectedDates.length === 0) {
        return false;
      }
      const dateArray = JSON.parse(localStorage.getItem("dateArray"));
      return selectedDates.some((selectedDate) => {
        const date = moment(selectedDate, "MM-DD-YYYY", true);
        return (
          date.isValid() &&
          dateArray.some((dateInArray) => {
            const arrayDate = moment(dateInArray, "MM-DD-YYYY", true);
            return arrayDate.isValid() && arrayDate.isSame(date, "day");
          })
        );
      });
    };
    setIsNextButtonDisabled(
      (prev) => selectedDates.length === 0 || hasSelectedDateInRange()
    );
  }, [selectedDates]);

  const handleSaveAndContinueClick = () => {
    if (
      selectedDates.length > 0 &&
      assigned_cart &&
      Object.keys(assigned_cart)?.length > 0
    ) {
      handleDateClick(selectedDates, hasBookingDates, setValues, onDateClick);
      setShowToast(false); 
      switchComponent("Searchcartone");
      goToNextStep();
    } else if (selectedDates.length > 0 && !assigned_cart) {
      handleDateClick(selectedDates, hasBookingDates, setValues, onDateClick);
      setShowToast(false);
      switchComponent("Searchcartone");
      goToNextStep();
    } else {
      Toast({ message: "No Cart Found", isError: true });
    }
  };


 

  const handleDateClick = (clickedDate) => {
    setError(null);
    const minimumBookingDays = Number(user?.default_vendor?.minimum_rentals_days );
    const storedDaysDifference = localStorage.getItem("daysDifference");
    const daysDifference = +storedDaysDifference;
 


    if(selectedVendor?.minimum_rentals_days)
    {
      if(clickedDate[0]?.day !== clickedDate[1]?.day && daysDifference >= selectedVendor?.minimum_rentals_days && daysDifference < 30)
      {
        setSelectedDates([clickedDate[0]?.day, clickedDate[1]?.day]);
        setIsNextButtonDisabled(false);
      }
      else
      {
        handleDateClickMinError(clickedDate, daysDifference,selectedVendor?.minimum_rentals_days);
        setIsNextButtonDisabled(true);
        setSelectedDates([]);
      }
    }
    else
    {
      if(clickedDate[0]?.day !== clickedDate[1]?.day && daysDifference >= user?.default_vendor?.minimum_rentals_days && daysDifference < 30 )
      {
        setSelectedDates([clickedDate[0]?.day, clickedDate[1]?.day]);
        setIsNextButtonDisabled(false);
      }
 
      else
      {
        handleDateClickMinError(clickedDate, daysDifference,minimumBookingDays);
        setIsNextButtonDisabled(true);
        setSelectedDates([]);
      }
    }
    
  };

  const handleDateClickMinError = (clickedDate, daysDifference, minimumDays) => {
    if (daysDifference >= 30) {
      Toast({ message: "Cannot Book More Than 30 days", isError: true });
    } 
    else if (daysDifference < minimumDays) { 
      Toast({ message: `Cannot Book Less Than ${selectedVendor?.minimum_rentals_days ?? 1} Days`, isError: true });
    }
    //  else if (clickedDate.length === 2 || daysDifference === 1) {
    //   Toast({ message: "Cannot Select One Day", isError: true });
    // }
    setIsNextButtonDisabled(true);
    setSelectedDates([]);
  };

  if (loader) {
    return (
      <div className="flex justify-center items-center w-full lg:h-[670px] md:h-[670px]  h-[87vh]">
        <Spinner className="spinner" isLoading={loader} />
      </div>
    );
  } else {
    return (
      <div className="bg-[#FFFFFF] rounded-[10px] flex flex-col justify-center items-center w-[100%]  md:h-[670px] h-[87vh]">
        <p className="md:my-[27px] my-[20px] md:text-[22px] text-[18px] font-bold text-Textdarkcolor w-[90%]">
          Booking
        </p>
        <div className=" w-[90%]  ">
          <div className="xl:w-[80%] w-full mx-auto">
            <div className=" w-[100%] xl:w-[70%] mx-auto text-white bg-Appcolor lg:h-[100px] h-[82px] rounded-lg p-[8px]">
              <p className="lg:text-[16px] text-[12px]">Note:</p>
              <ul className="list-decimal ml-9 ">
                <li className="lg:mt-[8px] mt-[3px] lg:text-[16px] text-[12px] lg:pl-[15px] pl-[10px]">
                  Delivery by 4pm
                </li>
                <li className="lg:mt-[8px] mt-[3px] lg:text-[16px] text-[12px] lg:pl-[15px] pl-[10px]">
                  Pick-up after 9am
                </li>
              </ul>
            </div>
            <div className="">
              <div className="mt-4 flex lg:w-[40%] w-[90%] lg:ml-[120px] ml-2">
                <p className="rounded-t-lg text-white w-[80%] p-[5px] bg-Appcolor">
                  Booking Availability
                </p>
              </div>
              <div className="main mx-auto lg:w-[70%] w-[100%] rounded-lg shadow-[5px_20px_10px_#8eb203] ">
                <div>
                  <Calender
                    selectedDates={selectedDates}
                    setIsNextButtonDisabled={setIsNextButtonDisabled}
                    onDateClick={handleDateClick}
                    hasBookingDates={bookDates}
                    // minDate={new Date()}  
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-between w-[100%] md:my-4 my-5">
            <button
              className="bg-Btncolor text-Textdarkcolor lg:text-[16px] text-[12px] rounded-[10px] lg:w-[145px] lg:h-[50px] md:w-[130px] md:h-[40px] w-[120px] h-[40px] "
              onClick={handleBackClick}
            >
              Back
            </button>
            <button
              className={`lg:text-[16px] text-[12px] rounded-[10px] lg:w-[150px] lg:h-[50px] md:w-[130px] md:h-[40px] w-[120px] h-[40px] ${
                isNextButtonDisabled
                  ? "cursor-block bg-gray-200 text-gray-600"
                  : "bg-Appcolor text-white"
              }`}
              onClick={handleSaveAndContinueClick}
              disabled={isNextButtonDisabled}
            >
              Save and Continue
            </button>
          </div>
        </div>
        
        {showToast && <ToastContainer limit={1} />}
        {/* <ToastContainer limit={1}/> */}

      </div>
    );
  }
};

export default Booking;
