

import React, { useState, useRef } from "react";
import { post } from "../../utils/axiosInstance";
import Spinner from "../Spinner";
import { getCardToken } from "../../utils/constants";
import Inputfield from "../Inputfiled";
import { useFormik } from "formik";
import Toast from "../Toast";
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css'
import { Bankcardschema } from '../../utils/schema'
import { useSelector } from "react-redux";

export function Bankcardmodal({ switchComponent }) {
  const [loader, setLoader] = useState(false);

  const Token = useSelector((state) => state.token);


  const formik = useFormik({
    initialValues: {
      number: '',
      expiry: '',
      name: '',
      cvc: '',
      focus: '',
    },

    validationSchema: Bankcardschema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = async () => {
    if (formik.values) {
      createCard({
        is_default: 0,
        token: await getCardToken({
          card_holder_name: formik.values.name,
          card_number: formik.values.number,
          card_expiry_date: formik.values.expiry,
          card_cvv: formik.values.cvc
          // focused: formik.values.focus
        }),
      });
    }
  };

  // const handleInputChange = (evt) => {
  //   const { name, value } = evt.target;
  //   formik.setValues((prev) => ({ ...prev, [name]: value }));
  //   formik.setFieldValue("focus", name);
  // }


  const formatExpiryDate = (value) => {
    const cleanedValue = value.replace(/\D/g, '');
    if (cleanedValue.length >= 3) {
      return `${cleanedValue.slice(0, 2)}/${cleanedValue.slice(2, 4)}`;
    }
    return cleanedValue;
  }


  const handleInputChange = (evt) => {
    const { name, value } = evt.target;

    if (name == "expiry") {
      const formattedValue = formatExpiryDate(value);
      console.log("formattedValue", formattedValue);
      formik.setValues((prev) => ({ ...prev, [name]: formattedValue }));
      formik.setFieldValue("focus", name);
    }
    else {
      formik.setValues((prev) => ({ ...prev, [name]: value }));
      formik.setFieldValue("focus", name);
    }

  }



  // export function formatExpirationDate(value) {
  //   const clearValue = clearNumber(value);

  //   if (clearValue.length >= 3) {
  //     return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  //   }

  console.log("formik.valuess", formik.values);


  const createCard = async (data) => {
    console.log('createCard', data)
    // const Token = await localStorage.getItem("token");
    setLoader(true);
    post(`/card/card`, data, {}, Token)
      .then((res) => {
        if ("response" in res) {
          setLoader(false);
          switchComponent("A");
        } else {
          setLoader(false);
          Toast({ message: "Please Add Correct Infromation", isError: true });
        }
      })
      .catch((err) => {
        setLoader(false);
        // console.log("err", err?.error?.messages[0]);
        Toast({ message: err.response.data.error.messages[0], isError: true });
      })
  };


  return (
    <>
      {/* <div className="justify-between items-center flex   fixed inset-0 z-50 ">
        <div className="w-[584px]  p-20 mx-auto justify-start">
          <div className="border-0 rounded-lg   shadow-lg relative flex flex-col justify-around bg-white "> */}

      <div className="justify-center items-center  flex     fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="  w-[584px]  p-20   mx-auto  ">
          <div className="border-0 rounded-lg shadow-lg  relative flex flex-col w-full bg-white outline-none focus:outline-none">



            <div className="flex bg-Appcolor p-5  border-b border-solid border-blueGray-200 rounded-t">
              <h3 className="lg:text-[18px] text-[16px] w-full flex justify-center mx-auto text-white font-medium">
                Payment Details
              </h3>
              <button
                className=" w-0 h-2 rounded-2xl mt-[-35px] mr-[-2px]"
                onClick={() => switchComponent(null)}
              >
                <span className="bg-white w-8 h-8 rounded-3xl text-black text-2xl block outline-none focus:outline-none ">
                  ×
                </span>
              </button>
            </div>










            <form className="p-4 lg:my-1" onSubmit={formik.handleSubmit} >
              <div className="relative lg:mt-[20px] mt-[10px] flex mx-auto lg:h-[200px] h-[150px] w-[90%]custom-scrollbar">
                <Cards
                  number={formik.values.number}
                  expiry={formik.values.expiry}
                  name={formik.values.name}
                  cvc={formik.values.cvc}
                  focused={formik.values.focus}
                />
              </div>
              <div className="w-90% mt-10">
                <Inputfield
                  divstyle={`mt-2 w-[90%]  mx-auto`}
                  inputStyle="relative flex rounded-xl my-1 outline-none  w-full md:p-4 p-3 mx-auto shadow-[0_5px_30px_-20px_#000000]"
                  name="number"
                  placeholder="Card Number"
                  type="text"
                  maxLength={19}
                  value={formik.values.number}
                  errors={formik.errors.number}
                  touched={formik.touched.number}
                  onChange={handleInputChange}
                // onFocus={handleInputFocus}
                />

                <Inputfield
                  divstyle={`mt-2 w-[90%]  mx-auto`}
                  inputStyle="relative flex rounded-xl my-1 outline-none  w-full md:p-4 p-3 mx-auto shadow-[0_5px_30px_-20px_#000000]"
                  name="expiry"
                  placeholder="Expiration (MM/YY)"
                  type="text"
                  maxLength={5}
                  // pattern="\d\d/\d\d"
                  onChange={handleInputChange}
                  // onFocus={handleInputFocus}
                  values={formik.values.expiry}
                  errors={formik.errors.expiry}
                  touched={formik.touched.expiry}
                />
                {/* <Inputfield
                  divstyle={`mt-2 w-[90%] mx-auto`}
                  inputStyle="relative flex rounded-xl my-1 outline-none w-full md:p-4 p-3 mx-auto shadow-[0_5px_30px_-20px_#000000]"
                  name="expiry"
                  placeholder="Expiration (MM/YY)"
                  type="text"
                  maxLength={5}  // Adjusted maxLength to include the slash
                  onChange={handleInputChange}
                  value={formik.values.expiry.replace(/(.{2})/, '$1/')}  // Added the slash between MM and YY
                  errors={formik.errors.expiry}
                  touched={formik.touched.expiry}
                /> */}



                <Inputfield
                  divstyle={`mt-2 w-[90%]  mx-auto`}
                  inputStyle="relative flex rounded-xl my-1 outline-none  w-full md:p-4 p-3 mx-auto shadow-[0_5px_30px_-20px_#000000]"
                  name="cvc"
                  placeholder="CVV"
                  type="text"
                  maxLength={4}
                  onChange={handleInputChange}
                  value={formik.values.cvc}
                  errors={formik.errors.cvc}
                  touched={formik.touched.cvc}
                />
                <Inputfield
                  divstyle={`mt-2 w-[90%]  mx-auto`}
                  inputStyle="relative flex rounded-xl my-1 outline-none  w-full md:p-4 p-3 mx-auto shadow-[0_5px_30px_-20px_#000000]"
                  name="name"
                  placeholder="Cardholder's Name"
                  type="text"
                  onChange={handleInputChange}
                  // onFocus={handleInputFocus}
                  value={formik.values.name}
                  errors={formik.errors.name}
                  touched={formik.touched.name}
                />
              </div>
              <div className="flex flex-col items-center justify-center lg:mt-[30px] sm:mt-[5px] mt-0  rounded-b">
                {loader ? (
                  <div className="flex justify-center items-center mb-5">
                    <Spinner className="spinner" isLoading={loader} />
                  </div>
                ) : (
                  <button
                    className="bg-Appcolor text-white my-[15px] font-medium px-2 py-3 rounded-md lg:w-[240px] w-[90%]  text-sm"
                    type="submit" >
                    Add Card
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  )
}

