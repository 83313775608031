// import React from "react";
// import Master from "../../assets/master.svg";
// import Visa from "../../assets/visa.svg";

// export function Selectcardmodal({ switchComponent, cardDetails }) {
//   return (
//     <>
//       <div className="justify-center items-center  flex   fixed inset-0 z-50 outline-none focus:outline-none">
//         <div className="  w-[584px]  p-20   mx-auto  ">
//           {/*content*/}
//           <div className="border-0 rounded-lg shadow-lg  relative flex flex-col w-full bg-white outline-none focus:outline-none">
//             {/*header*/}
//             <div className="flex bg-Appcolor p-5 border-b border-solid border-blueGray-200 rounded-t">
//               <h3 className="text-[18px]   w-full flex justify-center mx-auto text-white font-medium">
//                 Select Payment Card
//               </h3>
//               <button
//                 className=" w-0 h-2 rounded-2xl mt-[-35px] mr-[-2px]"
//                 onClick={() => switchComponent(null)}
//               >
//                 <span className="bg-white w-8 h-8 rounded-3xl text-black text-2xl block outline-none focus:outline-none">
//                   ×
//                 </span>
//               </button>
//             </div>
//             {/*body*/}
//             {
//               cardDetails && cardDetails.map((item, ind) => {
//                 <div className="relative lg:mt-[40px] mt-8 flex  rounded-xl w-[90%] p-4 mx-auto shadow-[0_5px_30px_-20px_#000000] ">
//                   <div className="flex items-center">
//                     <input
//                       className="flex w-[20px] h-[20px] "
//                       type="radio"
//                       name="radio"
//                     />
//                     <img className="w-[49px] h-[49px] ml-[18px]" src={Master} />
//                     <div className="flex flex-col ml-[16px]">
//                       <p>Master Card</p>
//                       <p>xxxx - xxxx - xxxx - 3273</p>
//                     </div>
//                   </div>
//                 </div>
//               })
//             }

//             {/* <div className="relative lg:mt-[26px] mt-4 flex   rounded-xl w-[90%] p-4 mx-auto shadow-[0_5px_30px_-20px_#000000]">
//               <div className="flex items-center">
//                 <input
//                   className="flex w-[20px] h-[20px]"
//                   type="radio"
//                   name="radio"
//                 />
//                 <img className="w-[49px] h-[49px] ml-[18px]" src={Visa} />
//                 <div className="flex flex-col ml-[16px]">
//                   <p>Visa Master</p>
//                   <p>xxxx - xxxx - xxxx - 8954</p>
//                 </div>
//               </div>
//             </div> */}
// <div className="flex flex-col items-center justify-center p-6   rounded-b">
//   <button
//     className="text-Appcolor lg:mt-[88px] mt-[65px]  shadow-[0_1px_20px_-15px_#000000] font-medium px-2 py-3 rounded-md w-[240px]   text-sm"
//     type="button"
//     onClick={() => switchComponent('A')}
//   >
//     + Add New Card
//   </button>

//   <button
//     className="bg-Appcolor text-white my-[36px]   font-medium px-2 py-3 rounded-md w-[240px]   text-sm"
//     type="button"
//     onClick={() => switchComponent('B')}
//   >
//     Select Card
//   </button>
// </div>
//           </div>
//         </div>
//       </div>
//       <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
//     </>
//   );
// }





import React, { useEffect, useState } from "react";
import Searchcard from "../../assets/Searchcard.svg";
import Spinner from "../Spinner";
// import { get } from "../../utils/axiosInstance";
import { get, del, post } from "../../utils/axiosInstance";
import Bin from '../../assets/binnn.svg'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Master from "../../assets/master.svg";
import Visa from "../../assets/visa.svg";
export function Selectcardmodal({ switchComponent }) {
  const [loader, setLoader] = useState(false);
  const [cards, setCards] = useState([]);
  useEffect(() => {
    allCards();
  }, []);



  const showErrorToastMessage = () => {
    toast.error("Card Not Deleted.", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  const showSuccessToastMessage = () => {
    toast.success("Card Deleted Successfully", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };


  const allCards = async () => {
    setLoader(true);
    const Token = await localStorage.getItem("token");
    get(`/card/cards`, {}, {}, Token)
      .then((res) => {
        if ("response" in res) {
          localStorage.setItem("myCards", JSON.stringify(res.response.data.cards));
          setCards(res.response.data.cards);
          setLoader(false);
        } else {
          console.log("====================================");
          console.log(res);
          console.log("====================================");
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };



  const handleDelete = (card_id) => {
    console.log("cardId", card_id);
    const Token = localStorage.getItem("token");
    var formdata = new FormData();
    formdata.append("card_id", card_id);

    post(`/card/delete`, formdata, {}, Token)
      .then((res) => {
        if ("response" in res) {
          showSuccessToastMessage()
          allCards();
        } else {
          showErrorToastMessage()
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const _renderData = () => {
    if (cards.length !== 0) {
      return (
        <div className="flex  xl:max-h-[500px]  md:max-h-[400px] max-h-[250px]  py-2 custom-scrollbar">
          <ul className="">
            {cards.map((i, ind) => (
              <li className=" w-[100%]">
                <div className="relative lg:mt-[10px]  flex rounded-xl w-[90%]  py-4 px-4 mx-auto shadow-[0_5px_20px_-10px_#000000]">
                  <div className="flex items-center">
                    <input
                      className="flex w-[20px] h-[20px]"
                      type="radio"
                      name="radio"
                      onClick={async () => {
                        localStorage.setItem("selectedCard", JSON.stringify(i));
                        switchComponent(null);
                      }}
                    />
                    <img
                      className="w-[49px] h-[49px] ml-[18px]"
                      src={i.brand == "Visa" ? Visa : Master}
                      alt="Master Card"
                    />
                    <div className="flex flex-col ml-[16px]">
                      <p>{i.brand}</p>
                      <p>xxxx-xxxx-xxxx-{i.last_4}</p>
                    </div>
                  </div>
                  <button
                    className="absolute top-0 right-0 p-2 cursor-pointer"
                    onClick={() => handleDelete(i.card_id)}>
                    <img src={Bin} alt="Bin Img" className="w-3 h-3" />
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      );
    } else {
      return (
        <>
          <img className="w-[157px] h-[107px] mt-5" src={Searchcard} />
          <p className="  text-[Textlitecolor] mt-[19px] text-[16px] text-center">
            Cards Not Available
          </p>
        </>
      );
    }
  };


  return (
    <>
      <div className="justify-center items-center  flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="  w-[584px]  p-20   mx-auto  ">
          <div className="border-0 rounded-lg shadow-lg  relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex bg-Appcolor p-5 border-b border-solid border-blueGray-200 rounded-t">
              <h3 className="text-[18px]   w-full flex justify-center mx-auto text-white font-medium">
                Select Payment Card
              </h3>
              <button
                className=" w-0 h-2 rounded-2xl mt-[-35px] mr-[-2px]"
                onClick={() => switchComponent(null)}
              >
                <span className="bg-white w-8 h-8 rounded-3xl text-black text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            <div className="relative  my-2    mx-auto lg:max-h-[500] ">
              {loader ? (
                <div className="flex justify-center items-center mb-5">
                  <Spinner className="spinner" isLoading={loader} />
                </div>
              ) : (
                _renderData()
              )}
            </div>
            {/*footer*/}
            {/* <div className="flex items-center  mx-auto w-[90%] justify-center pb-4  rounded-b">
              <button
                className="text-Appcolor background-blue-700 font-medium px-2 py-3 rounded-md w-[240px] border-2 text-sm"
                type="button"
                onClick={() => {
                  switchComponent("A");
                }}
              >
                {" "}
                + Add New Card
              </button> */}
            <div className="flex flex-col items-center justify-center rounded-b">
              <button
                className="text-Appcolor lg:mt-[28px] mt-[65px]  shadow-[0_1px_20px_-15px_#000000] font-medium px-2 py-3 rounded-md w-[240px]   text-sm"
                type="button"
                onClick={() => switchComponent('A')}
              >
                + Add New Card
              </button>

              <button
                className="bg-Appcolor text-white my-[10px]   font-medium px-2 py-3 rounded-md w-[240px]   text-sm"
                type="button"
                onClick={() => switchComponent('B')}
              >
                Select Card
              </button>

            </div>
          </div>
        </div>
        <ToastContainer limit={1} />
      </div >
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}