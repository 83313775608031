import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  Polyline,
  Polygon,
  PolylineF,
  PolygonF,
  useLoadScript,
} from "@react-google-maps/api";

// const center = {
//   lat: 30.3,
//   lng: -86,
// };







function Map({
  containerStyle,
  onClick,
  selectedLocation,
  placesAutocomplete,
  wayPoints,
  wayPointsPolygon,
}) {
  const isLoaded = true
  // const { isLoaded } = useLoadScript({
  //   id: "google-map-script",
  //   googleMapsApiKey: "AIzaSyBAY9jgE-CPAPcU78LqNJ6xquCX01LNcp4",
  //   libraries: ["places"],
  // });

  //
  const [center, setCenter] = useState({ lat: 30.3, lng: -86 }); // Initial center of the map


  const [map, setMap] = useState(null); // State to hold the Google Map instance

  const handleMapLoad = (mapInstance) => {
    setMap(mapInstance);
  };

  // Function to move the map camera to a specific location
  const moveToLocation = (location) => {
    setCenter(location);
    map && map.panTo(location); // Move the map camera to the specified location
  };

  // Use effect to move the map camera when selectedLocation changes
  useEffect(() => {
    if (selectedLocation) {
      moveToLocation(selectedLocation.location);
    }
  }, [selectedLocation]);

  //
  const [tabLocation, setTabLocation] = useState(null);
  const [markers, setMarkers] = useState([]);
  const options = {
    disableDefaultUI: true,
    streetViewControl: false,
    fullscreenControl: false,
    mapTypeControl: false,
    zoom: 12,
    center: center,
  };
  const handleMapClick = (event) => {
    const latLng = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };

    const newMarker = {
      latLng: latLng,
      id: markers.length,
    };

    setMarkers([...markers, newMarker]);
    onClick && onClick(latLng);
  };

  return isLoaded ? (
    <div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={options.center}
      
      
        zoom={options.zoom}
        options={options}
        onClick={handleMapClick}
      >


 
 









        {placesAutocomplete && (
          <div
            style={{
              position: "absolute",
              zIndex: 1,
              width: "90%",
              margin: "20px 20px 0px 20px",
            }}
          >
            {placesAutocomplete}
          </div>
        )}
        {selectedLocation && <Marker position={selectedLocation.location} />}
        {/* {tabLocation && <Marker position={tabLocation.location} title={selectedLocation.address} />} */}
        {wayPointsPolygon.map((feature, index) => {
          const coordinates = feature.geometry.coordinates[0].map(
            (coordinate) => ({
              lat: coordinate[1],
              lng: coordinate[0],
            })
          );

          return (
            <Polygon
              key={index}
              paths={coordinates}
              options={{
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#FF0000",
                fillOpacity: 0.35,
              }}
            />
          );
        })}
        <Polyline
          path={wayPoints}
          options={{
            strokeColor: "#0075FF",
            strokeWeight: 2,
            fillColor: "#0075FF",
            fillOpacity: 0.35,
          }}
        />
      </GoogleMap>
    </div>
  ) : (
    <div>Loading...</div>
  );
}

export default Map;
