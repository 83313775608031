import React, { useState } from "react";
import { Calendar } from "react-multi-date-picker";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { handleDateClick } from "../components/Calenderutils";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import "./index.css";
import Toast from "./Toast";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
export default function CalendarComponent({
  onDateClick,
  hasBookingDates,
  setIsNextButtonDisabled,
}) {
  const [values, setValues] = useState([]);
  const [saveRange, serSaveRange] = useState(null);
  const selectedVendor = useSelector((state) => state.selectedVendor);
  const user = useSelector((state) => state.user);


  const handleDateClickInCalendar = (ranges) => {
    serSaveRange(ranges);
    hasBookingDates !== null
      ? handleDateClick(
          ranges,
          hasBookingDates,
          setValues,
          onDateClick,
          setIsNextButtonDisabled
        )
      : setRangeData(ranges);

    const storedDaysDifference = localStorage.getItem("daysDifference");

    // console.log("storedDaysDifference",storedDaysDifference);

    if (ranges.length === 1) {
      Toast({
        message: `Delivery Date: ${ranges[0]?.day}  ${ranges[0].month.name} ${ranges[0].year}`,
        isError: false,
        autoClose: 2000,
        position: "bottom-center",
      });
    } else {
      if (selectedVendor?.minimum_rentals_days ?? user?.default_vendor?.minimum_rentals_days <= storedDaysDifference) {
        if (ranges.length > 1) {

          Toast({
            message: `Delivery Date: ${ranges[0]?.day}  ${ranges[0].month.name} ${ranges[0].year}`,
            isError: false,
            autoClose: 2000,
            position: "bottom-center",
          });


          Toast({
            message: `Pickup Date: ${ranges[1]?.day}   ${ranges[1].month.name} ${ranges[1].year} `,
            isError: false,
            autoClose: 2000,
            position: "bottom-center",
          });
        }
      }
    }
    // console.log("hbahsdbhadhjs calneder main =====>",    selectedVendor?.minimum_rentals_days);

    return {
      style: { color: "white" },
    };
  };

  const setRangeData = (ranges) => {
    if (ranges[0] !== undefined && ranges[1] !== undefined) {
      const start = ranges[0].format("MM/DD/YYYY");
      const end = ranges[1].format("MM/DD/YYYY");
      const startDate = moment(start, "MM/DD/YYYY");
      const endDate = moment(end, "MM/DD/YYYY");
      const dateArray = [];
      const currentDate = startDate.clone();
      while (currentDate.isSameOrBefore(endDate)) {
        dateArray.push(currentDate.format("MM-DD-YYYY"));
        currentDate.add(1, "day");
      }
    }
  };

  return (
    <div className="shadow-none">
      <Calendar
        style={{ width: "100%" }}
        range={true}
        value={values}
        dateSeparator=" to "
        // plugins={[<DatePanel eachDaysInRange style={{ color: "white" }} />]}
        calendarPosition="top-left"
        fixMainPosition
        onChange={handleDateClickInCalendar}
        mapDays={({ date, selected }) => {
          const strDate = date.format("MM/DD/YYYY");
          const isPastDate = moment(strDate, "MM/DD/YYYY").isBefore(
            moment(),
            "day"
          );
          // const isCurrentDate = date.isSame(moment(), "day");
          // const isCurrentDate = moment(date).isSame(moment(), "day");
          const isCurrentDate = moment(strDate, "MM/DD/YYYY").isSame(
            date,
            "day"
          );

          if (hasBookingDates !== null) {
            if (isCurrentDate || isPastDate) {
              return {
                className: "unavailable ",
                style: { color: "gray" },
                disabled: true,
              };
            } else if (hasBookingDates.includes(strDate)) {
              return {
                className: "booked",
                style: { color: "blue" },
                disabled: true,
              };
            } else {
              return {
                className: "select",
                style: { color: "green" },
                disabled: false,
              };
            }
          } else {
            if (isCurrentDate || isPastDate) {
              return {
                className: "unavailable",
                style: { color: "gray" },
                disabled: true,
              };
            } else if (selected) {
              return {
                className: "afteravailable",
                style: { color: "white" },
                disabled: false,
              };
            } else {
              return {
                className: "select",
                style: { color: "green" },
                disabled: false,
              };
            }
          }
        }}
      />
      <div className="shadow-[3px_5px_25px_#c2c8d2]  border-[1px] border-b border-l border-r border-[#c2c8d2]">
        <div className="w-full grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1  lg:m-[10px] m-[5px]">
          <div className=" flex items-center lg:gap-[10px] gap-[5px]">
            <div className="bg-[#8eb203] xl:w-[15px]  xl:h-[15px] lg:w-[10px] lg:h-[10px] w-[10px] h-[10px] rounded-full" />
            <p className="md:text-[16px] text-[12px]">Available</p>
          </div>
          <div className="flex items-center lg:gap-[10px] gap-[5px]">
            <div className=" bg-[#b9bdc3] xl:w-[15px] xl:h-[15px] lg:w-[10px] lg:h-[10px] w-[10px] h-[10px] rounded-full" />
            <p className="md:text-[16px] text-[12px]">Unavailable</p>
          </div>
          <div className="flex items-center lg:gap-[10px] gap-[5px]">
            <div className="bg-[#0075FF] xl:w-[15px] xl:h-[15px] lg:w-[10px] lg:h-[10px] w-[10px] h-[10px] rounded-full" />
            <p className="md:text-[16px] text-[12px]">Booked</p>
          </div>
        </div>
      </div>
      <ToastContainer limit={1} />
    </div>
  );
}
