import React from 'react';
import Tags from '../../assets/Png/Tags.png'

export function VendorDiscountModal({ onClose, apiData }) {
    console.log("apiData=>", apiData);
    return (
        <>
            <div className="justify-between items-center flex   fixed inset-0 z-50 ">
                <div className="w-[600px]  p-20 mx-auto justify-start">
                    <div className="border-0 rounded-lg   shadow-lg relative flex flex-col justify-around bg-white ">
                        <div className="flex bg-Appcolor p-5  border-b border-solid border-blueGray-200 rounded-t">
                            <h3 className="lg:text-[18px] text-[16px] w-full flex justify-center mx-auto text-white font-medium">
                                Vendor Discount
                            </h3>
                            <button
                                className=" w-0 h-2 rounded-2xl mt-[-35px] mr-[-2px]"
                                onClick={() => onClose(null)}  >
                                <span className="bg-white w-8 h-8 rounded-3xl text-black text-2xl block ">
                                    ×
                                </span>
                            </button>
                        </div>
                        <div className="p-4 lg:my-1 mx-auto" >
                            <div className="relative lg:mt-[20px] text-center mt-[10px] flex-col mx-auto    w-[100%] custom-scrollbar" >
                                <div className='flex justify-center items-center'>
                                    <img className='w-4 h-4' src={Tags} />
                                    <p className='text-Appcolor ml-2'>{apiData.value}{`${apiData.operation_type == "amount" ? "$" : "%"}`}</p>
                                </div>

                                <p className='w-[80%] text-Textlitecolor mx-auto line-clamp-4 mt-2'>{apiData?.description}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}

