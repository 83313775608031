import React, { useState, useEffect } from "react";
import Inputfield from "../../components/Inputfiled";
import Map from "../Map";
import { useFormik } from "formik";
import { Vendoraddressschema } from "../../utils/schema";
import { useNavigate } from "react-router-dom";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Search from "../../assets/search.svg";
import { get, post } from "../../utils/axiosInstance";
import { isPointInPolygon } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { setUserState } from "../../state";
import Toast from "../Toast";

const Vendoraddressinfo = ({ switchComponent, goToNextStep, goToPreviousStep }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const [data, setData] = useState([]);
  const [address, setAddress] = useState("");
  const [ploygonData, setPloygonData] = useState([]);

  const formik = useFormik({
    initialValues: {
      propertyaddress: "",
      city: "",
      state: "",
      zipcode: "",
      garagecode: "",
      unit: "",
      gatecode: "",
      housecode: "",
      latitude: "",
      longitude: "",
    },

    validationSchema: Vendoraddressschema,
    onSubmit: async (values) => {
      handleDoneLocationClick()

    },
  });

  useEffect(() => {
    getData();
  }, []);

  // const getData = async () => {
  //   try {
  //     const res = await get(`/content/content/map-geo-fence`, {}, {}, {});
  //     const coordinates =
  //       res.response.data.geo_fence.description.features[0].geometry
  //         .coordinates;
  //     const data = coordinates.map((coordinate) => ({
  //       lat: coordinate[1],
  //       lng: coordinate[0],
  //     }));
  //     const polygon =
  //       res.response.data.geo_fence.description.features.slice(1);
  //     setPloygonData(polygon);
  //     setData(data);
  //   } catch (err) {
  //     console.error("API Error:", err);
  //   }
  // };

 


 

  const app_vendor_id = useSelector((state) => state.app_vendor_id);

  console.log("asdasdas====>",app_vendor_id);
  const Token = useSelector((state) => state.token);




  // const getData =   () => {
  //   get(`/vendor/geofencing/${app_vendor_id}`, {}, {}, Token)
  //     .then((res) => {
  //       console.log("res=================>", res.response);
  //       const geoFenceData = res.response.data.geo_fence;
  
  //       // Check if there are any features available in any of the geoFenceData objects
  //       let allFeatures = [];
  //       geoFenceData.forEach((fence) => {
  //         if (fence.geofencing.features.length > 0) {
  //           allFeatures.push(...fence.geofencing.features);
  //         }
  //       });
  
  //       if (allFeatures.length > 0) {
  //         const coordinates = allFeatures[0].geometry.coordinates;
  //         const data = coordinates.map((coordinate) => ({
  //           lat: coordinate[1],
  //           lng: coordinate[0],
  //         }));
  //         const polygons = allFeatures.slice(1); // Here you'll have all polygons
  //         setPloygonData(polygons);
  //         setData(data);
  //       } else {
  //         console.error("No features available in the response.");
  //       }
  //     })
  //     .catch((err) => {
  //       console.error("API Error:", err);
  //     });
  // };
  
  
  


  // const getData = async () => {
  //   try {
  //     const res = await get(`/vendor/geofencing/${app_vendor_id}`, {}, {}, Token)
  //     console.log("res====>",res);
  //     const geoFenceData = res.response.data.geo_fence;
  
  //     // Check if there are any features available in any of the geoFenceData objects
  //     let allFeatures = [];
  //     geoFenceData.forEach((fence) => {
  //       if (fence.geofencing.features.length > 0) {
  //         allFeatures.push(...fence.geofencing.features);
  //       }
  //     });

  //     if (allFeatures.length > 0) {
  //       const coordinates = allFeatures[0].geometry.coordinates;
  //       const data = coordinates.map((coordinate) => ({
  //         lat: coordinate[1],
  //         lng: coordinate[0],
  //       }));
  //       const polygons = allFeatures.slice(1); // Here you'll have all polygons
  //       setPloygonData(polygons);
  //       setData(data);
  //     } else {
  //       console.error("No features available in the response.");
  //     }

  //   } catch (err) {
  //     console.error("API Error:", err);
  //   }
  // };

  
  useEffect(() => {
    getData();
  }, []);

  
  const getData = () => {
    get(`/vendor/geofencing/${app_vendor_id}`, {}, {}, Token)
      .then((res) => {
        // console.log("res====== ===========>", res.response);
        const geoFenceData = res.response.data.geo_fence;
  
        // Check if there are any features available in any of the geoFenceData objects
        let allFeatures = [];
        geoFenceData.forEach((fence) => {
          if (fence.geofencing.features.length > 0) {
            allFeatures.push(...fence.geofencing.features);
          }
        });
  
        if (allFeatures.length > 0) {
          const coordinates = allFeatures[0].geometry.coordinates;
          const data = coordinates.map((coordinate) => ({
            lat: coordinate[1],
            lng: coordinate[0],
          }));
          const polygons = allFeatures.slice(1); // Here you'll have all polygons
          setPloygonData(polygons);
          setData(data);
        } else {
          // console.error("No features available in the response.");
          Toast({ message: 'No features available in the response.', isError: true });

        }
      })
      .catch((err) => {
        console.error("API Error:", err);
      });
  };
  


  


  const handleDoneLocationClick = async () => {
    const isWithinPolygon = isPointInPolygon(selectedLocation.location, data);
    if (isWithinPolygon) {
      let data = {
        // vendor_id: localStorage.getItem("vendorid"),
        vendor_id: app_vendor_id,
        property_address: formik.values.propertyaddress,
        garage_code: formik.values.garagecode,
        gate_code: formik.values.gatecode,
        house_code: formik.values.housecode,
        unit: formik.values.unit,
        city: formik.values.city,
        state: formik.values.state,
        zip: formik.values.zipcode,
        latitude: formik.values.latitude,
        longitude: formik.values.longitude,
      };

      try {
        const Token = localStorage.getItem("token");
        const res = await post("/address/create", data, {}, Token);
        if (res) {
          const AddressData = res.response.data.address;
          const addressId = AddressData.id;
          dispatch(setUserState({
            ...user,
            address: AddressData
          }));

          localStorage.setItem("addressId", addressId);
          localStorage.setItem(
            "selectedLatLng",
            JSON.stringify({
              latitude: formik.values.latitude,
              longitude: formik.values.longitude,
            })
          );

          localStorage.setItem("selectedAddressData",
            JSON.stringify({
              latitude: selectedLocation.location.lat,
              longitude: selectedLocation.location.lng,
              full_address: selectedLocation.address,
            })
          );

          localStorage.setItem("bookedDates", null);
          switchComponent("Vendorcartinfo");
          goToNextStep();


        }
        else {
        }
      } catch (error) {
        console.error("Error sending request:", error);
      }
    } else {
      // alert("Can not select restricted area");
      // toast("Can not select restricted area", {
      //   position: "bottom-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   type: 'error'
      // })

      Toast({ message: 'Can not select restricted area.', isError: true });



    }

  };

  const fetchPlaceDetailsFromGoogleMapsAPI = (placeId) => {
    return new Promise((resolve, reject) => {
      const placesService = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );

      placesService.getDetails({ placeId }, (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          resolve(place);
        } else {
          reject(new Error(`Failed to fetch place details. Status: ${status}`));
        }
      });
    });
  };

  const handleChange = (newAddress) => {
    setAddress(newAddress);

    formik.setValues({
      ...formik.values,
    });
  };

  const handleSelect = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      const placeDetails = await fetchPlaceDetailsFromGoogleMapsAPI(
        results[0].place_id
      );

      // console.log('Place Details:', placeDetails);

      setAddress(results[0].formatted_address)
      const updatedValues = {
        propertyaddress: results[0].formatted_address,
        city: getAddressComponent(placeDetails, "locality") || "",
        state: getAddressComponent(placeDetails, "administrative_area_level_1") || "",
        zipcode: getAddressComponent(placeDetails, "postal_code") || "",
        unit: "",
        garagecode: formik.values.garagecode,
        gatecode: formik.values.gatecode,
        housecode: formik.values.housecode,
        latitude: latLng.lat,
        longitude: latLng.lng,
      }

      formik.setValues({
        ...formik.values,
        ...updatedValues
      });

      setSelectedLocation({
        address,
        location: latLng,
      });
    }
    catch (error) {
      console.error("Error selecting address", error);
    }
  };

  // const handleBackClick = () => {
  //   switchComponent("Vendors");
  //   goToPreviousStep();
  // };

  const getAddressComponent = (placeDetails, type) => {
    const component = placeDetails.address_components.find((component) =>
      component.types.includes(type)
    );
    return component ? component.long_name : "";
  };

  return (
    <div className="bg-[#FFFFFF] rounded-[10px] flex flex-col items-center lg:w-[100%] lg:h-[670px] md:h-[670px]  h-[87vh]">
      <form
        className="w-[90%] h-[650px]  custom-scrollbar mt-[1px]  "
        onSubmit={formik.handleSubmit} >
        <div className="mx-auto lg:gap-x-28 grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1">
          <PlacesAutocomplete value={address} onChange={handleChange} onSelect={handleSelect} >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div className="relative">
                <div className="relative lg:w-[80%] w-[80%] lg:mr-0 mr-2 z-10 my-1 top-[60px]">
                  <div className="absolute inset-y-0  xl:-mt-[2px] lg:mt-6  mt-[45px] lg:top-2   -top-10 flex items-center text-start  pointer-events-none">
                    <img
                      className="lg:w-3 lg:h-4 w-3 h-3 md:mt-0  mt-1 text-gray-500"
                      src={Search}
                    />
                  </div>
                </div>
                <label className="flex   text-[18px] pt-[20px] text-Inputtextcolor font-regular">
                  Property Address*
                </label>
                <div className="relative">
                  <input
                    {...getInputProps({
                      placeholder: "Search address",
                      className:
                        "w-full py-2 pl-5 border-b-2 outline-0 border-[#f5f5f5]",
                    })}
                  />
                  <div className="absolute top-full z-10 bg-white w-full  rounded-lg mt-1">
                    {loading && <div className="p-2">Loading...</div>}
                    {suggestions.map((suggestion) => (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className: "p-2 cursor-pointer",
                        })}
                      >
                        {suggestion.description}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </PlacesAutocomplete>

          <Inputfield
            labelstyle="flex text-[18px]  pt-[20px] text-Inputtextcolor  font-regular"
            inputStyle=" w-full py-2 border-b-2 outline-0  border-[#f5f5f5] "
            // Labelname="Unit*"
            Labelname="Unit"
            type="text"
            placeholder="Enter your Unit"
            name="unit"
            htmlFor="unit"
            id="unit"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.unit}
          />

          <Inputfield
            labelstyle="flex text-[18px]   pt-[20px] text-Inputtextcolor  font-regular"
            inputStyle=" w-full py-2 border-b-2 outline-0 border-[#f5f5f5] "
            Labelname="City*"
            type="text"
            placeholder="Enter your City"
            name="city"
            htmlFor="city"
            id="city"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.city}
            errors={formik.errors.city}
            touched={formik.touched.city}
            isEditing={true}
          />

          <Inputfield
            labelstyle="flex text-[18px]  pt-[20px] text-Inputtextcolor  font-regular"
            inputStyle="w-full py-2 border-b-2 outline-0 border-[#f5f5f5] "
            Labelname="State*"
            type="text"
            placeholder="Enter your State"
            name="state"
            htmlFor="state"
            id="state"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.state}
            errors={formik.errors.state}
            touched={formik.touched.state}
            isEditing={true}
          />

          <Inputfield
            labelstyle="flex text-[18px]  pt-[20px] text-Inputtextcolor  font-regular"
            inputStyle=" w-full py-2 border-b-2 outline-0 border-[#f5f5f5] "
            Labelname="Zip Code*"
            type="numeric"
            placeholder="Enter your Zip Code"
            name="zipcode"
            htmlFor="zipcode"
            id="zipcode"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.zipcode}
            errors={formik.errors.zipcode}
            touched={formik.touched.zipcode}
            isEditing={true}
          />

          <Inputfield
            labelstyle="flex text-[18px]   pt-[20px] text-Inputtextcolor  font-regular"
            inputStyle=" w-full py-2 border-b-2 outline-0 border-[#f5f5f5] "
            // Labelname="Garage Code*"
            Labelname="Garage Code"
            type="numeric"
            placeholder="Enter your Garage Code"
            name="garagecode"
            htmlFor="garagecode"
            id="garagecode"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.garagecode}
          />

          <Inputfield
            labelstyle="flex text-[18px]   pt-[20px] text-Inputtextcolor  font-regular"
            inputStyle=" w-full py-2 border-b-2 outline-0 border-[#f5f5f5] "
            // Labelname="Gate Code*"
            Labelname="Gate Code"
            type="numeric"
            placeholder="Enter your Gate Code"
            name="gatecode"
            htmlFor="gatecode"
            id="gatecode"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.gatecode}
          />

          <Inputfield
            labelstyle="flex text-[18px]   pt-[20px] text-Inputtextcolor  font-regular"
            inputStyle=" w-full py-2 border-b-2 outline-0 border-[#f5f5f5] "
            Labelname="House Code"
            type="numeric"
            placeholder="Enter your House Code"
            name="housecode"
            htmlFor="housecode"
            id="housecode"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.housecode}
          />
        </div>

        <div className="lg:w-[100%] w-[100%]  my-2   ">
          <Map containerStyle={{ height: "275px" }}
            wayPoints={data}
            wayPointsPolygon={ploygonData}
            selectedLocation={selectedLocation}
          />
        </div>

        <div className="flex justify-between w-[100%] my-4">
          {/* <button
            className="bg-Btncolor text-Textdarkcolor lg:text-[16px] text-[12px] rounded-[10px] lg:w-[145px] lg:h-[50px] md:w-[130px] md:h-[40px] w-[110px] h-[35px]"
            onClick={handleBackClick} > Back
          </button> */}

          <button
            className={`text-[16px] rounded-[10px]  lg:w-[145px] lg:h-[50px] md:w-[130px] md:h-[40px] w-[100%] h-[40px] 
              ${formik.isValid && formik.dirty ? "bg-Appcolor text-white" : "bg-gray-200 text-gray-500"}`}
            type="submit"
            disabled={!formik.isValid || !formik.dirty}
          >
            Next
          </button>

        </div>
      </form>
      <ToastContainer limit={1} />

    </div>
  );
};

export default Vendoraddressinfo;