import React from "react";

const Checkbox = ({
    Labelname,
    type,
    placeholder,
    labelstyle,
    inputStyle,
    divstyle,
    name,
    htmlFor,
    id,
    onChange,
    values,
    errors,
    touched,
    Validate,
    innerdiv,
    maindiv,
    disabled,
    onClick
}) => {
    return (
        <div className={divstyle}>
            {Validate ? (
                <>
                    <div className={maindiv}>
                        <div className={innerdiv}>
                            <input
                                id={id}
                                name={name}
                                onChange={onChange}
                                value={values}
                                className={inputStyle}
                                type={type}
                                placeholder={placeholder}
                                onClick={onClick}
                            />

                            <label htmlFor={htmlFor} className={labelstyle}>
                                {Labelname}
                            </label>
                        </div>
                        {errors && touched && <div className="text-red-500">{errors}</div>}
                    </div>
                </>
            ) : (
                <div>
                    <input
                        id={id}
                        name={name}
                        onChange={onChange}
                        value={values}
                        className={inputStyle}
                        type={type}
                        disabled={disabled}
                        placeholder={placeholder}
                        onClick={onClick}


                    />
                    <label htmlFor={htmlFor} className={labelstyle}>
                        {Labelname}
                    </label>
                </div>
            )}
        </div>
    );
};
export default Checkbox;
